import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class UserForm {
  @JsonProperty('email', String)
  public email: string = '';
  @JsonProperty('familyName', String)
  public familyName: string = '';
  @JsonProperty('givenName', String)
  public givenName: String = '';
  @JsonProperty('enabled', Boolean)
  public enabled: boolean = true;

  public equals(u: UserForm) {
    return (
      u != null &&
      this.familyName === u.familyName &&
      this.givenName === u.givenName &&
      this.email === u.email &&
      this.enabled === u.enabled
    );
  }
}