import {Injectable} from '@angular/core';

import {Contact} from '../../models/contact';
import {MyJsonConvert} from '../../utils/my-json-convert';
import {GPerson} from './../../models/google/g-person';
import {ContactsApiService} from './../google/contacts-api.service';

declare var gapi: any;

@Injectable()
export class GoogleApiService {
  private myJsonConvert: MyJsonConvert = new MyJsonConvert();

  constructor(
    private contactsApiService: ContactsApiService
  ) {
  }

  async getContacts(nextPageToken: string) {
    gapi.client.getToken();
    const params: object = {
      resourceName: 'people/me',
      personFields:
        'names,emailAddresses,organizations,phoneNumbers,userDefined',
    };
    if (nextPageToken != null) {
      params['pageToken'] = nextPageToken;
    }
    return await gapi.client.people.people.connections.list(params);
  }

  async createContact(contact: Contact) {
    const person: GPerson = this.contactsApiService.createPerson(contact);
    await gapi.client.people.people.createContact(
      this.myJsonConvert.serializeObject(person)
    );
  }
}
