import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { Column, Contact, ContactDto, ContactForm, ContactService, GoogleApiService } from '@crm/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { BottomSheetData } from 'projects/crm-core/src/lib/models/bottom-sheet-data';
import { EndpointEnum } from 'projects/crm-core/src/lib/models/enums/endpoint.enum';
import { FunctionEnum } from 'projects/crm-core/src/lib/models/enums/function.enum';
import { RouteEnum } from 'projects/crm-core/src/lib/models/enums/route.enum';
import { EventService } from 'projects/crm-core/src/lib/services/event/event.service';
import { SuccessGetEvent } from 'projects/crm-core/src/lib/services/event/success-get.event';
import { MyJsonConvert } from 'projects/crm-core/src/lib/utils/my-json-convert';

import { CrmContactsDialogComponent } from './dialog/crm-contacts-dialog.component';

@Component({
  selector: 'crm-contacts',
  templateUrl: './crm-contacts.component.html',
  styleUrls: ['./crm-contacts.component.scss']
})
export class CrmContactsComponent implements OnDestroy {
  public mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  @Input()
  public contacts: ContactDto[];
  @Input()
  public cols: Column[];
  public selectedContact: Contact;
  public contact: Contact;
  public newContact: boolean;
  public displayDialog: boolean;
  public isEditable = false;
  private updatedContact: ContactDto;

  protected myJsonConvert = new MyJsonConvert();
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private contactService: ContactService,
    private apiGwClientService: ApiGwClientService,
    private googleApiAuthService: GoogleApiService,
    private router: Router,
    private eventService: EventService,
    private translate: TranslateService,
    private bottomSheet: MatBottomSheet,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
  }

  public onRowSelect(event) {
    this.openDialog(event.data);
  }

  private openDialog(contactDto: ContactDto): void {
    const bottomSheetRef = this.bottomSheet.open(CrmContactsDialogComponent, {
      data: contactDto
    });

    bottomSheetRef.afterDismissed().subscribe((bottomSheetData: BottomSheetData) => {
      if (bottomSheetData) {
        switch (bottomSheetData.action) {
          case ("upload"):
            this.uploadContact(bottomSheetData.data as Contact);
            break;
          case ("save"):
            this.save(bottomSheetData.data as ContactForm);
            break;
          case ("anonymize"):
            this.anonymize(bottomSheetData.data);
            break;
        }
      }
    });

  }

  public handleActionDial(event) {
    switch (event) {
      case ("add"):
        this.addContact();
        break;
      case ("import"):
        this.router.navigate(['/contacts/import']);
        break;
    }
  }

  private uploadContact(contact: Contact): void {
    this.googleApiAuthService.createContact(contact);
    this.eventService.publish(new SuccessGetEvent(this.translate.instant('toaster.body.contact.export')));

  }

  private anonymize(params: any): void {
    this.apiGwClientService.routeDelete(
      EndpointEnum.CRM,
      RouteEnum.CONTACT,
      FunctionEnum.ANONYMIZE,
      params,
      this,
      (response) => {
        this.updatedContact = response.data;
        this.contacts.splice(this.contacts.findIndex(c => c.uuid === this.updatedContact.uuid), 1);
        this.eventService.publish(new SuccessGetEvent(this.translate.instant('toaster.body.contact.anonymize')));
      }
    )
  }

  protected getObjectContactClazz(): new () => ContactDto {
    return ContactDto;
  };

  private save(contactForm: ContactForm): void {

    this.apiGwClientService.routePut(
      EndpointEnum.CRM,
      RouteEnum.CONTACT,
      FunctionEnum.SAVE,
      contactForm,
      this,
      (response) => {
        this.updatedContact = this.myJsonConvert.deserializeObject((response.data), this.getObjectContactClazz());
        (this.contacts.findIndex(c => c.uuid === this.updatedContact.uuid) != -1)
          ? (this.contacts[
            this.contacts.findIndex(c => c.uuid === this.updatedContact.uuid)
          ] = this.updatedContact)
          : this.contacts.push(this.updatedContact);
        this.eventService.publish(new SuccessGetEvent(this.translate.instant('toaster.body.contact.save')));
      }
    );
  }

  public addContact(): void {
    this.openDialog(null);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
  }
}
