import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { HttpLoaderFactory } from 'projects/crm-i18n/src/public-api';
import { CrmGoogleImportContactComponent } from './crm-google-import-contact.component';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  declarations: [CrmGoogleImportContactComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    PickListModule,
    MatStepperModule,
    TableModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [CrmGoogleImportContactComponent]
})
export class CrmGoogleImportContactModule { }
