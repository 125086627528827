import { GoogleSigninModule } from './google-signin/google-signin.module';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HttpLoaderFactory } from '@crm/i18n';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './../app-routing.module';
import { NavModule } from './nav/nav.module';
import { AccountMenuModule } from './account-menu/account-menu.module';
import { CrmLoaderModule } from 'projects/crm-loader/src/public-api';
import { GdprDeclarationModule } from './gdpr-declaration/gdpr-declaration.module';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // Material modules
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    CrmLoaderModule
  ],
  exports: [NavModule, GoogleSigninModule, AccountMenuModule, GdprDeclarationModule]
})
export class SharedModule { }
