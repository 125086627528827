import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar.component';
import { CrmCalendarModule } from '@crm/calendar';
import { CrmLoaderModule } from 'projects/crm-loader/src/public-api';



@NgModule({
  declarations: [CalendarComponent],
  imports: [
    CommonModule,
    CrmCalendarModule,
    CrmLoaderModule
  ]
})
export class CalendarModule { }
