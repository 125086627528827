import { JsonObject, JsonProperty } from 'json2typescript';

import { StringToBooleanConverter } from './../../../utils/string-to-boolean-converter';

@JsonObject
export class GUserDefined {
    @JsonProperty("key", String, true)
    public key: string = "CRM";
    @JsonProperty("value", StringToBooleanConverter, true)
    public value: boolean = false;
}