<!-- Header : dialog title and Toggle Edit button -->
<div class="dialog-header">
  <h1>{{ "contact.details.title" | translate }}</h1>
  <button
    matTooltip="{{ 'common.upload' | translate }}"
    matTooltipPosition="below"
    mat-button
    class="button-right"
    (click)="onUploadContact()"
    *ngIf="!isEditable && !contact.deleted"
  >
    <mat-icon
      color="primary"
      fontIcon="fa-external-link-alt"
      fontSet="fas"
    ></mat-icon>
  </button>
  <button
    matTooltip="{{ 'common.edit' | translate }}"
    matTooltipPosition="below"
    class="button-right"
    mat-button
    (click)="toggleEdit()"
    *ngIf="!isEditable && !contact.deleted"
  >
    <mat-icon color="primary" fontIcon="fa-edit" fontSet="fas"></mat-icon>
  </button>
  <button
    matTooltip="{{ 'contact.anonymize' | translate }}"
    matTooltipPosition="below"
    mat-button
    class="button-right"
    *ngIf="!isEditable && !contact.deleted"
    (click)="onAnonymize()"
  >
    <mat-icon color="primary" fontIcon="fa-user-minus" fontSet="fas"></mat-icon>
  </button>
</div>

<!-- Contact edit form ; mutually exclusive with contact detail display -->
<div *ngIf="isEditable; else displayDetail" class="contact-details-container">
  <div class="contact-details-form" [formGroup]="contactFormGroup">
    <!--Given Name -->
    <table class="name-full-width" cellspacing="1">
      <tr>
        <td>
          <mat-form-field class="contact-detail-input-text">
            <mat-icon matPrefix>person</mat-icon>

            <input
              matInput
              placeholder="{{ 'common.information.givenName' | translate }}"
              value="{{ contact.givenName }}"
              formControlName="givenName"
            />
          </mat-form-field>
        </td>
        <!-- Family Name-->
        <td>
          <mat-form-field class="contact-detail-input-text">
            <input
              matInput
              placeholder="{{ 'common.information.familyName' | translate }}"
              value="{{ contact.familyName }}"
              formControlName="familyName"
            />
          </mat-form-field>
        </td>
      </tr>
    </table>

    <!-- Email -->
    <mat-form-field class="contact-detail-input-text">
      <mat-icon matPrefix>email</mat-icon>
      <input
        matInput
        placeholder="{{ 'common.information.email' | translate }}"
        value="{{ contact.email }}"
        formControlName="email"
      />
    </mat-form-field>
    <!-- Telephone -->
    <div class="phone-numbers">
      <div>
        <mat-icon matPrefix>phone</mat-icon>
      </div>
      <div class="phone-inputs">
        <mat-form-field class="contact-detail-input-text">
          <input
            matInput
            placeholder="{{ 'common.information.mobilePhone' | translate }}"
            value="{{ contact.mobilePhone }}"
            formControlName="mobilePhone"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'common.information.workPhone' | translate }}"
            value="{{ contact.workPhone }}"
            formControlName="workPhone"
          />
        </mat-form-field>
      </div>
    </div>
    <!-- Comment -->
    <div class="contact-detail-input-text">
      <mat-icon matPrefix>mode_comment</mat-icon>
      <crm-rich-text-editor formControlName="comment" [(ngModel)]="contact.comment"
                    [canEdit]="isEditable"></crm-rich-text-editor>
      <!-- <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
        placeholder="{{ 'common.information.comment' | translate }}"
        formControlName="comment"
      ></textarea> -->
    </div>

    <mat-slide-toggle color="primary" formControlName="enabled">
      <span
        *ngIf="contactFormGroup.get('enabled').value; else statusDisabled"
        translate
        >common.enabled</span
      >
      <ng-template #statusDisabled
        ><span translate>common.disabled</span></ng-template
      >
    </mat-slide-toggle>

    <!-- Entity : list of existing entities + button to show a form to create a new one -->
    <div class="entity-select-container">
      <mat-form-field *ngIf="!entityFormVisible">
        <mat-icon matPrefix>business</mat-icon>
        <mat-label>{{ "entity.type" | translate }}</mat-label>
        <mat-select
          name="test"
          formControlName="entity"
          [compareWith]="compareEntities"
        >
          <mat-option>--</mat-option>
          <mat-option
            *ngFor="let entity of filteredEntityList | async"
            [value]="entity"
          >
            {{ entity.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Button to show/hide the entity creation form -->
      <div class="button-container">
        <button
          mat-button
          *ngIf="entityFormVisible"
          (click)="toggleEntityForm()"
          class="toggle-button"
          color="primary"
        >
          <mat-icon>cancel</mat-icon>
          {{ "common.cancel" | translate }}
        </button>
        <button
          mat-button
          *ngIf="!entityFormVisible"
          (click)="toggleEntityForm()"
          class="toggle-button"
          color="primary"
        >
          <mat-icon>add_circle</mat-icon>
          {{ "common.add" | translate }}
        </button>
      </div>
    </div>
  </div>

  <!-- Entity creation form -->
  <mat-divider *ngIf="entityFormVisible"></mat-divider>
  <div *ngIf="entityFormVisible" class="new-entity-container">
    <h3 class="mat-h3 entity-title">
      {{ "contact.details.newEntity.title" | translate }}
    </h3>
    <crm-entity-edit [parentFormGroup]="contactFormGroup"></crm-entity-edit>
  </div>
</div>

<!-- Contact detail display ; mutually exclusive with the Contact Edit form-->
<ng-template #displayDetail>
  <mat-list class="contact-display-detail">
    <mat-list-item>
      <mat-icon matListIcon>person</mat-icon>
      <p mat-line>{{ contact.familyName }} {{ contact.givenName }}</p>
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListIcon>email</mat-icon>
      <p mat-line>{{ contact.email }}</p>
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListIcon>phone</mat-icon>
      <p mat-line>
        <span class="block"
          ><span>{{ contact.mobilePhone }}</span
          ><span class="type">{{
            "common.information.mobilePhone" | translate
          }}</span></span
        >
        <span class="block"
          ><span>{{ contact.workPhone }}</span
          ><span class="type">{{
            "common.information.workPhone" | translate
          }}</span></span
        >
      </p>
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListIcon>business</mat-icon>

      <div style="display: contents;">
        <span mat-line> {{ contact.entity.name }}</span>
        <button
          *ngIf="contact.entity.name"
          style="margin-left: 5px;"
          mat-button
          (click)="toggleHistory()"
        >
          <mat-icon *ngIf="visibleSidebar2">arrow_forward_ios</mat-icon>
          <mat-icon *ngIf="!visibleSidebar2">history</mat-icon>
        </button>
      </div>
    </mat-list-item>
    <mat-list-item>
      <mat-icon matListIcon>mode_comment</mat-icon>
      <div mat-line [innerHTML]="contact.comment"></div>
    </mat-list-item>
    <mat-list-item>
      <p mat-line>
        <mat-slide-toggle
          color="primary"
          [disabled]="true"
          [checked]="contact.enabled"
        >
          <span *ngIf="contact.enabled; else statusDisabled" translate
            >common.enabled</span
          >
          <ng-template #statusDisabled
            ><span translate>common.disabled</span></ng-template
          >
        </mat-slide-toggle>
      </p>
    </mat-list-item>
  </mat-list>
</ng-template>

<!-- Bottom action buttons : close the dialog (cancel) or save (if in edit mode) -->
<div>
  <button mat-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>{{ "common.close" | translate }}
  </button>
  <button
    *ngIf="isEditable && hasBeenModified"
    mat-button
    (click)="onSave()"
    cdkFocusInitial
    [disabled]="!contactFormGroup.valid"
  >
    <mat-icon>save</mat-icon>{{ "common.save" | translate }}
  </button>
</div>

<p-sidebar [(visible)]="visibleSidebar2" position="right" baseZIndex="1000">
  <h1 style="font-weight:normal">Previous entities</h1>
  <span *ngIf="!contact.oldEntities.length"> No Previous entities</span>
  <div *ngIf="contact.oldEntities.length != 0">
    <div *ngFor="let entity of contact.oldEntities">{{ entity.name }}</div>
  </div>
  <br />
</p-sidebar>
