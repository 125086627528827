<!-- Header : dialog title -->
<div class="dialog-header">
    <h1>{{ 'user.details.title' | translate }}</h1>
</div>

<!-- User edit form ; mutually exclusive with user detail display -->
<div *ngIf="isEditable; else displayDetail" class="user-details-container">
    <div class="user-details-form" [formGroup]="userFormGroup">
        <!-- Name -->
        <table class="name-full-width" cellspacing="1">
            <tr>
                <td>
                    <mat-form-field class="contact-detail-input-text">
                        <mat-icon matPrefix>person</mat-icon>

                        <input matInput placeholder="{{ 'common.information.givenName' | translate }}" value="{{ userForm.givenName }}" formControlName="givenName" />
                    </mat-form-field>
                </td>
                <!-- Family Name-->
                <td>
                    <mat-form-field class="contact-detail-input-text">
                        <input matInput placeholder="{{ 'common.information.familyName' | translate }}" value="{{ userForm.familyName }}" formControlName="familyName" />
                    </mat-form-field>
                </td>
            </tr>
        </table>
        <!-- Email -->
        <mat-form-field class="user-detail-input-text">
            <mat-icon matPrefix>email</mat-icon>
            <input matInput placeholder="{{ 'common.information.email' | translate }}" value="{{ userForm.email }}" formControlName="email" />
        </mat-form-field>
        <mat-error *ngIf="userFormGroup.get('email').invalid && userFormGroup.get('email').touched">
            {{validatorUtils.getErrorMessage(userFormGroup.get('email'))}}
          </mat-error>
    </div>
</div>
<!-- User detail display ; mutually exclusive with the User Edit form-->
<ng-template #displayDetail>
    <mat-list class="user-display-detail">

        <mat-list-item>
            <mat-icon matListIcon>person</mat-icon>
            <p mat-line> {{user.familyName}} {{user.givenName}}</p>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>email</mat-icon>
            <p mat-line> {{user.email}}</p>
        </mat-list-item>
        <mat-list-item>
            <p mat-line>
                <mat-slide-toggle color="primary" [checked]="user.enabled" (change)="onChange($event.checked)">
                    <span *ngIf="statusEnabled; else statusDisabled" translate>common.enabled</span>
                    <ng-template #statusDisabled><span translate>common.disabled</span></ng-template>
                    
                </mat-slide-toggle>
            </p>
        </mat-list-item>
    </mat-list>
</ng-template>

<!-- Bottom action buttons : close the dialog (cancel) or save (if in edit mode) -->
<div>
    <button mat-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>{{ 'common.close' | translate }}
  </button>
    <button *ngIf="!isEditable && userHasChanged" mat-button (click)="onChangeSave()">
    <mat-icon>save</mat-icon>{{ 'common.save' | translate }}
  </button>
    <button *ngIf="isEditable" mat-button (click)="onSave()" cdkFocusInitial [disabled]="!userFormGroup.valid">
    <mat-icon>save</mat-icon>{{ 'common.save' | translate }}
  </button>
</div>
