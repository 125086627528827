import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CrmContactsModule } from '@crm/contacts';

import { ContactsComponent } from './contacts.component';
import { CrmLoaderModule } from 'projects/crm-loader/src/public-api';

@NgModule({
  declarations: [ContactsComponent],
  imports: [CommonModule, CrmContactsModule, HttpClientModule, CrmLoaderModule],
  providers: []
})
export class ContactsModule { }
