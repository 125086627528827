import { JsonObject, JsonProperty } from 'json2typescript';

import { ExtendedProp as ExtendedProps } from './extendedProp';
import { HasId } from './base/has-id';

@JsonObject
export class CalendarEvent extends HasId{
  @JsonProperty("title", String)
  public title: string = "";
  @JsonProperty("start", Date)
  public start: Date = new Date();
  @JsonProperty("end", Date)
  public end: Date = new Date;
  @JsonProperty("allDay", Boolean)
  public allDay: boolean = false;
  @JsonProperty("color", String, true)
  public color?: string = null;
  @JsonProperty("extendedProps", ExtendedProps, true)
  public extendedProps: ExtendedProps = new ExtendedProps();

  public equals(o: CalendarEvent): boolean {
    return (
      this.title === o.title &&
      this.start === o.start &&
      this.end === o.end &&
      this.allDay === o.allDay &&
      this.extendedProps.equals(o.extendedProps)
    );
  }

  public hasChanged(o: CalendarEvent): boolean {
    return !(
      this.title === o.title &&
      this.start.getTime() === o.start.getTime() &&
      this.end.getTime() === o.end.getTime() &&
      this.allDay === o.allDay &&
      this.extendedProps.equals(o.extendedProps)
    );
  }

  public isActive(startDate: Date, endDate: Date): boolean{
    return (
      this.start.getTime() < this.end.getTime() &&
      (
        this.start.getTime()>=startDate.getTime() &&
        this.end.getTime()<=endDate.getTime()
        ) ||
      (
        this.end.getTime()>= startDate.getTime() &&
        this.end.getTime()<= endDate.getTime()
      )  ||
      (
        this.start.getTime()>= startDate.getTime() &&
        this.start.getTime()<= endDate.getTime()
      )
    )
  }
}