import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Participant } from 'projects/crm-core/src/lib/models/base/participant';

import { CalendarEvent } from '../models/calendar-event';
import { CalendarEventDto } from '../models/dto/calendar-event-dto';
import { Contact } from './../models/contact';
import { ContactUser } from './../models/contact-user';

@Injectable({
  providedIn: 'root'
})
export class CalendarEventService {
  constructor() {}

  public cloneEvent(event: CalendarEventDto): CalendarEventDto {
    if (event === null) {
      return null;
    }

    const clone = new CalendarEventDto();

    clone.uuid = event.uuid;
    clone.start = event.start;
    clone.end = event.end;
    clone.allDay = event.allDay;
    clone.title = event.title;
    clone.attachments = Object.assign([], event.attachments);
    clone.comment = event.comment;
    clone.location = event.location;
    clone.description = event.description;
    clone.participants = Object.assign([], event.participants);
    clone.googleID = event.googleID;
    return clone;
  }

  fromContactOrContactUserToParticipant(ct: Contact | ContactUser): Participant {
    const p: Participant = new Participant();

    p.email = ct.email;
    p.familyName = ct.familyName;
    p.givenName = ct.givenName;
    p.participantType = ct.participantType;
    p.uuid = ct.uuid;

    return p
  }

  public isSameDay(data): boolean {
    return moment(data.start.getTime()).isSame(moment(data.end.getTime()), 'day');
  }

  public formToCalendarEvent(value: any): CalendarEvent {
    const event: CalendarEvent = new CalendarEvent();
    let starttmp: number;
    let endtmp: number;
    event.title = value.title;
    event.id = value.id;
    if (value.start){
       starttmp= value.start.startOf('day').unix()*1000;
    }
    if (value.end) {
      endtmp = value.end.startOf('day').unix()*1000;
    }
    event.allDay = value.allDay;
    event.extendedProps.attachments = value.attachments;
    event.extendedProps.comment = value.comment;
    event.extendedProps.description = value.description;
    event.extendedProps.participants = value.participants;
    event.extendedProps.location = value.location;
    event.extendedProps.googleID = value.googleID;

    if (!event.allDay) {
      if (value.startTime)
        starttmp += value.startTime;
      if (value.endTime)
        endtmp += value.endTime;
    }

    event.start = new Date(starttmp);
    event.end = new Date(endtmp);
    return event;
  }

  public formToCalendarDto(value: any): CalendarEventDto {
    const cdto = new CalendarEventDto();
    cdto.uuid = value.id;
    cdto.title = value.title;
    cdto.allDay = value.allDay;
    if (moment.isMoment(value.start)) {
      cdto.start = (value.start.startOf('day')).unix()*1000;
    } else {
      cdto.start = (value.start as Date).getTime()*1000;
    }
    if (moment.isMoment(value.end)) {
      cdto.end = (value.end.startOf('day')).unix()*1000;
    } else {
      cdto.end = (value.end as Date).getTime()*1000;
    }

    if (!cdto.allDay) {
      if (value.startTime)
        cdto.start += value.startTime;
      if (value.endTime)
        cdto.end += value.endTime;
    }

    cdto.description = value.description;
    cdto.comment = value.comment;
    cdto.attachments = value.attachments;
    cdto.participants = value.participants;
    cdto.location = value.location;
    cdto.googleID = value.googleID;

    return cdto;
  }

  public fromFullCalendarEvent(fullCalendarEvent): CalendarEvent {
    const result = new CalendarEvent();
    result.allDay = fullCalendarEvent.allDay;
    result.start = fullCalendarEvent.start;
    result.end = fullCalendarEvent.end;
    result.id = fullCalendarEvent.id;
    result.title = fullCalendarEvent.title;
    result.extendedProps.description = fullCalendarEvent.extendedProps.description;
    result.extendedProps.comment = fullCalendarEvent.extendedProps.comment;
    result.extendedProps.attachments =
      fullCalendarEvent.extendedProps.attachments;
    result.extendedProps.location = fullCalendarEvent.extendedProps.location;
    result.extendedProps.googleID = fullCalendarEvent.extendedProps.googleID;
    return result;
  }

  public fromCalendarDtoToFullCalendarEvent(event: CalendarEventDto): CalendarEvent {
    const fullCalendarEvent: CalendarEvent = new CalendarEvent();
    fullCalendarEvent.id = event.uuid;
    fullCalendarEvent.allDay = event.allDay;
    fullCalendarEvent.end = new Date(event.end);
    fullCalendarEvent.start = new Date(event.start);
    fullCalendarEvent.title = event.title;
    fullCalendarEvent.extendedProps.comment = event.comment;
    fullCalendarEvent.extendedProps.attachments = event.attachments;
    fullCalendarEvent.extendedProps.description = event.description;
    fullCalendarEvent.extendedProps.participants = event.participants;
    fullCalendarEvent.extendedProps.location = event.location;
    fullCalendarEvent.extendedProps.googleID = event.googleID;
    return fullCalendarEvent
  }

  public fromFullCalendarToCalendarEvent(fce: CalendarEvent): CalendarEventDto {
    const calendarEvent: CalendarEventDto = new CalendarEventDto();
    calendarEvent.title = fce.title;
    calendarEvent.start = (fce.start as Date).getTime();
    calendarEvent.end = (fce.end as Date).getTime();
    calendarEvent.allDay = fce.allDay;
    calendarEvent.description = fce.extendedProps.description;
    calendarEvent.comment = fce.extendedProps.comment;
    calendarEvent.attachments = fce.extendedProps.attachments;
    calendarEvent.location = fce.extendedProps.location;
    calendarEvent.uuid = fce.id;
    calendarEvent.googleID = fce.extendedProps.googleID;

    return calendarEvent;
  }
}
