import { JsonCustomConvert } from 'json2typescript';
import { MyJsonConvert } from '../../my-json-convert';

export abstract class MapStringConverter<V> implements JsonCustomConvert<object> {
  private myJsonConvert: MyJsonConvert = new MyJsonConvert();

  public serialize(map: Map<string, V>): any {
    const result: object = {};
    map.forEach((value, key) => {
      if (this.getClazz(value) === null) {
        result[key.toString()] = value;
      } else {
        result[key.toString()] = this.myJsonConvert.serialize(value);
      }
    });

    return result;
  }

  public deserialize(map: any): Map<string, V> {
    const result: Map<string, V> = new Map();
    for (const k of Object.keys(map)) {
      if (this.getClazz(map[k]) === null) {
        result.set(k, map[k]);
      } else {
        result.set(k, this.myJsonConvert.deserializeObject(map[k], this.getClazz(map[k])));
      }
    }
    return result;
  }

  protected abstract getClazz(object: any): new () => V;
}