import {Component, OnInit, ViewChild, TemplateRef, AfterViewInit} from '@angular/core';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { MatDialog } from '@angular/material/dialog';
import { GoogleApiService, UserService, EndpointEnum, RouteEnum, FunctionEnum } from '@crm/core';
import { Router } from '@angular/router';
import { environment } from 'projects/crm-app/src/environments/environment';
import { AwsAuthService } from 'projects/crm-core/src/lib/services/auth/aws-auth.service';

@Component({
  selector: 'app-gdpr-declaration',
  templateUrl: './gdpr-declaration.component.html',
  styleUrls: ['./gdpr-declaration.component.scss']
})
export class GdprDeclarationComponent implements AfterViewInit {

  @ViewChild('dialogContent', { static: true })
  public dialogContent: TemplateRef<any>;

  constructor(
    private readonly apiGwClientService: ApiGwClientService,
    private awsAuthService: AwsAuthService,
    public dialog: MatDialog,
    private router: Router,
    private readonly userService: UserService,
  ) { }

  public ngAfterViewInit(): void {
    if (!this.userService.hasAcceptedGdpr()) {
      this.displayDialog();
    }
  }

  public onAcceptClick(): void {
    // TODO: Enregistrer le choix via l'API
    this.apiGwClientService.routePut(
      EndpointEnum.USER,
      RouteEnum.ACCOUNT,
      FunctionEnum.ACCEPT_GDPR,
      {},
      this,
      () => {
        // TODO: Afficher un message de confirmation
        this.dialog.closeAll();
      }
    );
  }

  public onRefuseClick(): void {
    this.dialog.closeAll();
  }

  private displayDialog(): void {
    this.dialog.open(
      this.dialogContent,
      {
        closeOnNavigation: false,
        disableClose: true,
        height: '100vh',
        width: '100vw'
      }
    );
  }

}
