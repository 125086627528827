import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { HasChannel } from './has-channel-interface';
import { filter, map } from 'rxjs/operators';

interface Event {
  channel: string;
  data: any;
}

@Injectable()
export class EventService {
  private eventBus: Observable<Event>;
  private eventBus$: Subject<Event>;

  constructor() {
    this.eventBus$ = new Subject<Event>();
    this.eventBus = this.eventBus$.asObservable();
  }

  public publish<T extends HasChannel>(event: T): void {
    const channel = event.channel;
    this.eventBus$.next({ channel, data: event });
  }

  public of<T extends HasChannel>(event: T): Observable<any> {
    const channel = event.channel;
    return this.eventBus.pipe(
      filter((m: Event) => m.channel === channel),
      map(m => m.data)
    );
  }
}
