import { JsonProperty, Any } from 'json2typescript';

import { HasUuid } from './base/has-uuid';


export abstract class FormContext<F extends HasUuid>{

  @JsonProperty('canEdit', Boolean, true)
  public canEdit = false;

  @JsonProperty('newObject', Boolean, true)
  public newObject = false;

  protected abstract getObjectFormClazz(): new () => F;

}
