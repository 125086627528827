<div class="box-container" [class.is-mobile]="mobileQuery.matches">
    <p-table styleClass="p-datatable-gridlines"
    #usersList
    [value]="users"
    selectionMode="single"
    [(selection)]="selectedUser"
    (onRowSelect)="onRowSelect($event)"
    [paginator]="true"
    [responsive]="true"
    [scrollable]="true"
    [rows]="15"
    [columns]="cols"
    sortMode="multiple"
    >
    <ng-template pTemplate="caption">
        <div
        class="user-table-caption"
        [class.is-mobile]="mobileQuery.matches"
        >
        <div>
            <mat-form-field>
                <mat-icon matPrefix>filter_list</mat-icon
                    ><input
                    type="text"
                    matInput
                    placeholder="{{ 'common.globalFilter' | translate }}"
                    (input)="
                    usersList.filterGlobal($event.target.value, 'contains')
                    "
                    /></mat-form-field>
                </div>
            </div>
        </ng-template>
        <!-- Table Headers -->
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{ col.header | translate }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
            <!-- Column filter -->
            <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field">
                    <p-multiSelect
                        appendTo="body"
                        *ngSwitchCase="'enabled'"
                        [options]="status"
                        defaultLabel="{{ 'common.information.status' | translate}}"
                        (onChange)="usersList.filter($event.value, col.field, 'in')">
                    </p-multiSelect>
                    <mat-form-field *ngSwitchDefault>
                        <mat-icon matPrefix>filter_list</mat-icon>
                        <input
                        matInput
                        type="text"
                        (input)="usersList.filter($event.target.value, col.field, 'contains')"/>
                    </mat-form-field>
                </th>
            </tr>
        </ng-template>

            <!-- Table Body -->
            <ng-template pTemplate="body" let-user let-columns="columns">
                <tr [pSelectableRow]="user">
                    <td *ngFor="let col of columns">
                        {{ user[col.field] }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <button
        mat-fab
        (click)="addUser()"
        [ngClass]="
        mobileQuery.matches ? 'add-button--mobile' : 'add-button--desktop'
        "
        color="primary"
        >
        <mat-icon>add</mat-icon>
    </button>
</div>
