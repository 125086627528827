import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Column, Entity, EntityService } from '@crm/core';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { EndpointEnum } from 'projects/crm-core/src/lib/models/enums/endpoint.enum';
import { RouteEnum } from 'projects/crm-core/src/lib/models/enums/route.enum';
import { FunctionEnum } from 'projects/crm-core/src/lib/models/enums/function.enum';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { EntityEditComponent } from 'projects/crm-core/src/lib/shared/entity-edit/entity-edit/entity-edit.component';
import { EventService } from 'projects/crm-core/src/lib/services/event/event.service';
import { SuccessGetEvent } from 'projects/crm-core/src/lib/services/event/success-get.event';
import { MatBottomSheetRef, MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'crm-entities',
  templateUrl: './crm-entities.component.html',
  styleUrls: ['./crm-entities.component.scss']
})
export class CrmEntitiesComponent implements OnInit, OnDestroy {
  @ViewChild('dialogContent', { static: true })
  public dialogContent: TemplateRef<any>;

  public mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  @Input()
  public entities: Entity[];
  @Input()
  public cols: Column[];
  public selectedEntity: Entity;
  public entity: Entity;
  public newEntity: boolean;
  public displayDialog: boolean;

  public status: SelectItem[];
  public entityClone: Entity;

  private responseEntity: Entity;
  private enableLabel: string;
  private disableLabel: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private entityService: EntityService,
    public bottomSheet: MatBottomSheet,
    private apiGwClientService: ApiGwClientService,
    private translateService: TranslateService,
    private eventService: EventService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);

    this.initSelect();
  }

  private initSelect() {
    this.translateService.get('common.enabled').subscribe((translation) => {
      this.enableLabel = translation;
    });
    this.translateService.get('common.disabled').subscribe((translation) => {
      this.disableLabel = translation;
    });
    this.status = [
      { label: this.enableLabel, value: true },
      { label: this.disableLabel, value: false }
    ];
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
  }

  public onRowSelect(event) {
    this.openBottomSheet(event.data);
  }

  private openBottomSheet(entity: Entity): void {
    this.entityClone = this.entityService.cloneEntity(entity);
    const bottomSheetRef = this.bottomSheet.open(EntityEditComponent, {
      data: entity
    });

    bottomSheetRef.afterDismissed().subscribe((result: Entity) => {
      if (result) {
        this.save(result);
      }
    });
  }

  private save(entity: Entity): void {
    this.apiGwClientService.routePut(
      EndpointEnum.CRM,
      RouteEnum.ENTITY,
      FunctionEnum.SAVE,
      entity,
      this,
      (response) => {
        this.responseEntity = response.data;
        (this.entities.findIndex(c => c.uuid === this.responseEntity.uuid) != -1)
          ? (this.entities[
            this.entities.findIndex(c => c.uuid === this.responseEntity.uuid)
          ] = this.responseEntity)
          : this.entities.push(this.responseEntity);
        this.eventService.publish(new SuccessGetEvent(
          this.translateService.instant('toaster.body.entities.save')));
      }
    );
  }

  public addEntity(): void {
    this.openBottomSheet(null);
  }
}
