import { CrmRichTextEditorModule } from './../../../crm-rich-text-editor/src/lib/crm-rich-text-editor.module';
import { CrmUploadModule } from './../../../crm-upload/src/lib/crm-upload.module';
import { MatAsTimeInputModule } from './../../../mat-as-time-input/src/lib/mat-as-time-input.module';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HttpLoaderFactory } from '@crm/i18n';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { CrmLoaderModule } from './../../../crm-loader/src/lib/crm-loader.module';
import { CrmCalendarEventDialogComponent } from './crm-calendar-event-dialog/crm-calendar-event-dialog.component';
import { CrmCalendarComponent } from './crm-calendar.component';
import {FullCalendarModule} from '@fullcalendar/angular';

@NgModule({
    declarations: [CrmCalendarComponent, CrmCalendarEventDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    //Material modules
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatIconModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    //Other libs
    // MatNativeDatetimeModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    NgxMatSelectSearchModule,
    CrmLoaderModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    MatChipsModule,
    MatAsTimeInputModule,
    CrmUploadModule,
    CrmRichTextEditorModule,
    FullCalendarModule,
  ],
    exports: [CrmCalendarComponent]
})
export class CrmCalendarModule {}
