import { JsonObject, JsonProperty } from 'json2typescript';

import { ContactBase } from './base/contact-base';
import { Entity } from './entity';

@JsonObject
export class ContactForm extends ContactBase {
  @JsonProperty('entity', Entity, true)
  public entity?: Entity = new Entity();
}
