import { ContactUser } from './../models/contact-user';
import { Contact } from './../models/contact';
import { MyJsonConvert } from 'projects/crm-core/src/lib/utils/my-json-convert';
import { JsonCustomConvert, JsonConverter } from 'json2typescript';

@JsonConverter
export class ArrayParticipantConverter implements JsonCustomConvert<object> {

    private myJsonConvert: MyJsonConvert = new MyJsonConvert();

    public serialize(participants: Array<Contact | ContactUser>) {
        const result: object[] = [];
        participants.forEach((value) => {
        if (typeof value === Contact.name || typeof value === ContactUser.name) {
            result.push(this.myJsonConvert.serialize(value));
        } else {
            result.push(value);
        }
        });

        return result;
    }
    
    public deserialize(participants: any): Array<Contact | ContactUser> {
        const result : Array<Contact | ContactUser> = new Array<Contact | ContactUser>();
        participants.forEach(participant => {
            if (participant.participantType === "Contact") {
                result.push(this.myJsonConvert.deserializeObject(participant, this.getContactClazz(participant)));
            } 
            if (participant.participantType === "ContactUser") {
                result.push(this.myJsonConvert.deserializeObject(participant, this.getContactUserClazz(participant)));
            }
        });

        return result;
    }

    protected getContactClazz(object: any): new() => Contact {
        return Contact;
    }

    protected getContactUserClazz(object: any): new() => ContactUser {
        return ContactUser;
    }

}