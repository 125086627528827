import {ConflictErrorEvent} from './../../../crm-core/src/lib/services/event/conflict-error.event';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {EventService} from 'projects/crm-core/src/lib/services/event/event.service';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {ServiceErrorEvent} from 'projects/crm-core/src/lib/services/event/service-error.event';
import {ServiceLinkErrorEvent} from 'projects/crm-core/src/lib/services/auth/service-link-error.event';
import {ValidationErrorEvent} from 'projects/crm-core/src/lib/services/event/validation-error.event';
import {SuccessEvent} from 'projects/crm-core/src/lib/services/event/success.event';
import {SuccessGetEvent} from 'projects/crm-core/src/lib/services/event/success-get.event';
import {PreviousObjectVersionEvent} from 'projects/crm-core/src/lib/services/event/previous-object-version-event.event';
import {DateConverter} from 'projects/crm-core/src/lib/utils/common/abstract/date-converter';
import {ToastContainerDirective, ToastrService} from 'ngx-toastr';

@Component({
  selector: 'crm-toaster',
  templateUrl: './crm-toaster.component.html',
  styleUrls: ['./crm-toaster.component.scss']
})
export class CrmToasterComponent implements OnInit, OnDestroy {

  @ViewChild(ToastContainerDirective, {static: true}) toastContainer: ToastContainerDirective;

  private serviceErrorSubscription: Subscription;
  private serviceLinkErrorSubscription: Subscription;
  private serviceValidationSubscription: Subscription;
  private successSubscription: Subscription;
  private previousObjectVersionSubscription: Subscription;
  private objectConflictErrorSubscription: Subscription;
  private readonly toastOptions = {positionClass: 'toast-top-right'};

  constructor(
    private readonly eventService: EventService,
    private readonly translate: TranslateService,
    private readonly datePipe: DatePipe,
    private readonly toastr: ToastrService
  ) {
  }

  public ngOnInit(): void {
    this.toastr.overlayContainer = this.toastContainer;
    this.serviceErrorSubscription =
      this.eventService.of(new ServiceErrorEvent(undefined)).subscribe((event) => {
        this.fillError(event);
      });
    this.serviceLinkErrorSubscription =
      this.eventService.of(new ServiceLinkErrorEvent(undefined)).subscribe((event) => {
        this.fillLinkError(event);
      });
    this.serviceValidationSubscription =
      this.eventService.of(new ValidationErrorEvent(undefined)).subscribe((event) => {
        this.fillValidation(event);
      });
    this.successSubscription =
      this.eventService.of(new SuccessEvent()).subscribe((event) => {
        this.fillSuccess(event);
      });
    this.successSubscription =
      this.eventService.of(new SuccessGetEvent(undefined)).subscribe((event) => {
        this.fillGetSuccess(event);
      });
    this.previousObjectVersionSubscription =
      this.eventService.of(new PreviousObjectVersionEvent(undefined)).subscribe((event) => {
        this.fillPreviousObjectVersionEvent(event);
      });
    this.objectConflictErrorSubscription =
      this.eventService.of(new ConflictErrorEvent(undefined)).subscribe((event) => {
        this.fillConflictError(event);
      });
  }

  public ngOnDestroy(): void {
    this.serviceErrorSubscription.unsubscribe();
    this.serviceLinkErrorSubscription.unsubscribe();
    this.serviceValidationSubscription.unsubscribe();
    this.successSubscription.unsubscribe();
  }

  private fillConflictError(event: ConflictErrorEvent): void {
    const messageErreurKey = `toaster.status.${event.err.status}`;
    const codeErreur = event.err.status;

    this.toastr.error(`[${codeErreur}] ${this.translate.instant(messageErreurKey)}`,
      this.translate.instant('toaster.title.error'),
      this.toastOptions);
  }

  private fillError(event: ServiceErrorEvent): void {
    const messageErreurKey = `toaster.status.${event.err.status}`;
    const codeErreur = event.err.status;

    this.toastr.error(`[${codeErreur}] ${this.translate.instant(messageErreurKey)}`,
      this.translate.instant('toaster.title.error'),
      this.toastOptions);
  }

  private fillLinkError(event: ServiceErrorEvent): void {
    const messageErreurKey = `ERROR.ESB_LINK_${event.err.data.esbMode}`;
    const codeErreur = event.err.status;

    this.toastr.error(`[${codeErreur}] ${this.translate.instant(messageErreurKey)}`,
      this.translate.instant('toaster.title.error'),
      this.toastOptions);
  }

  private fillValidation(event: ValidationErrorEvent): void {
    if (event.err.data) {
      Object.keys(event.err.data).forEach(k => {
        this.toastr.warning(event.err.data[k], k, this.toastOptions);
      });
    } else {
      const code = event.err.status;
      this.toastr.warning(`[${code}] ${this.translate.instant('toaster.status.' + code)}`,
        this.translate.instant('toaster.title.validation'),
        this.toastOptions);
    }
  }

  private fillPreviousObjectVersionEvent(event: PreviousObjectVersionEvent): void {
    const code = event.err.status;
    const dateConverter: DateConverter = new DateConverter();
    const args = {
      utilisateurNomPrenom: event.err.data.utilisateurNomPrenom,
      dateMaj: this.datePipe.transform(dateConverter.deserialize(event.err.data.dateMaj), 'dd/MM/yyyy HH:mm zzzz')
    };

    this.toastr.warning(`[${code}] ${this.translate.instant('ERROR.' + code, args)}`,
      this.translate.instant('toaster.title.previous_version_event'),
      this.toastOptions);
  }

  private fillSuccess(event: SuccessEvent): void {
    this.toastr.success('success', this.translate.instant('toaster.title.success'), this.toastOptions);
  }

  private fillGetSuccess(event: SuccessGetEvent): void {
    this.toastr.success(event.body, this.translate.instant('toaster.title.success'), this.toastOptions);
  }

}
