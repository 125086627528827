<div *ngIf="isLoading; else main">
    <crm-loader></crm-loader>
</div>

<ng-template #main>
    <crm-toaster></crm-toaster>
    <div class="app-container" [class.is-mobile]="mobileQuery.matches" *ngIf="isLogged; else login ">
        <mat-toolbar class="navbar-header">
            <div class="nav-menu-container">
                <button mat-icon-button class="nav-menu-button" (click)="menu.toggle()">
                    <mat-icon class="nav-menu-icon">menu</mat-icon>
                </button>
            </div>
            <div class="nav-logo-container">
                <img class="nav-app-logo" src="/assets/crm/assets/images/AIR_LIQUIDE_CRM.png" alt="CRM logo"
                    (click)="navigateHome()" />
            </div>
            <div class="nav-button-container">
                <app-google-menu></app-google-menu>
            </div>
            <div class="nav-button-container">
                <app-account-menu></app-account-menu>
            </div>
        </mat-toolbar>

        <mat-drawer-container class="sidenav-container" hasBackdrop="true">
            <mat-drawer #menu [mode]="mobileQuery.matches ? 'over' : 'side'" fixedTopGap="48">
                <mat-nav-list>
                    <div *ngFor="let menuItem of menuItems; index as i">
                        <mat-divider *ngIf="i > 0 && menuItem.level === 0"></mat-divider>
                        <!-- Menu header with a link -->
                        <a mat-list-item *ngIf="menuItem.url && menuItem.level == 0" [routerLink]="menuItem.url"
                            (click)="menu.close()" class="nav-list-header nav-list-link">
                            {{ menuItem.name | translate }}
                        </a>
                        <!-- Menu item with a link -->
                        <a mat-list-item *ngIf="menuItem.url && menuItem.level == 1" [routerLink]="menuItem.url"
                            (click)="menu.close()" class="nav-list-subitem nav-list-link">
                            {{ menuItem.name | translate }}
                        </a>
                    </div>
                </mat-nav-list>
            </mat-drawer>
            <mat-drawer-content>
                <router-outlet></router-outlet>
            </mat-drawer-content>
        </mat-drawer-container>
        <app-gdpr-declaration></app-gdpr-declaration>
    </div>
</ng-template>

<ng-template #login>
    <app-google-signin></app-google-signin>
</ng-template>
