<button mat-icon-button pButton label="Basic" (click)="op1.toggle($event)">
  <mat-icon>apps</mat-icon>
</button>

<p-overlayPanel #op1>
    <div class="grid" style="width: 170px;">
        <div *ngFor="let app of apps">
            <div class="col-6">
                <a class="link" target="_blank " href={{app.link}}>
                    <p class="app-logo " [ngStyle]="app.style "></p>
                    <p class="app-name ">{{app.name}}</p>
                </a>
            </div>
        </div>
    </div>

</p-overlayPanel>
