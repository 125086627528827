import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class StringToBooleanConverter implements JsonCustomConvert<boolean> {
    public serialize(data: boolean): string {
        const stringBool: string = (data)? "true": "false";
        return stringBool;
    }
    
    public deserialize(data:string): boolean {
        const bool: boolean = (data.trim().toLowerCase() === "true")? true: false;
        return bool;
    }


}