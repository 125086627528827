import { HasKey } from './base/has-key';


import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class FileS3 implements HasKey<string> {

    @JsonProperty('deleted', Boolean, true)
    public deleted: boolean = false;
    @JsonProperty('fileMIME', String, true)
    public fileMIME: string = "";

    @JsonProperty('fileURI', String, true)
    public fileURI: string = "";
    @JsonProperty('fileName', String, true)
    public fileName: string = "";
    @JsonProperty('fileExtension', String, true)
    public fileExtension: string = "";
    @JsonProperty('s3Key', String, true)
    public s3Key: string = "";

  public byteArray: string;
  public file: File;

  public getKey(): string {
    return this.fileName;
  }

}