import { Injectable } from '@angular/core';
import { Entity } from './../models/entity';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  constructor() {}

  public formToEntity(value: any): Entity {
    const entity = new Entity();
    entity.uuid = value.uuid;
    entity.name = value.name;
    entity.address = value.address;
    entity.comment = value.comment;
    entity.phone = value.phone;
    entity.enabled = value.enabled;
    return entity;
  }

  cloneEntity(entity: Entity): Entity {
    if (entity === null) {
      return null;
    }
    const clone: Entity = new Entity();
    clone.name = entity.name;
    clone.address = entity.address;
    clone.phone = entity.phone;
    clone.comment = entity.comment;
    clone.uuid = entity.uuid;
    clone.enabled = entity.enabled;
    return clone;
  }
}
