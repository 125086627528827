import { JsonObject, JsonProperty } from 'json2typescript';

import { MapUuidEntityConverter } from '../utils/map-uuid-entity-converter';
import { Contact } from './contact';
import { Entity } from './entity';
import { FormContext } from './form-context';


@JsonObject
export class ContactFormContext extends FormContext<Contact> {
  
  @JsonProperty('entityByUuidMap', MapUuidEntityConverter, true)
  public entityByUuidMap: Map<String, Entity> = new Map<String, Entity>();

  //FIXME: Remonter objectForm dans FormContext avec un type generique. PB: Le deserializer ne rentre pas dans l'object et le prend tel quel sans le transformer en Contact.
  @JsonProperty('objectForm', Contact, true)

  public objectForm: Contact = null;

  protected getObjectFormClazz(): new () => Contact {
    return Contact;
  }
}
