import {Component, OnInit} from '@angular/core';
import {GoogleAuthenticationService} from "@crm/core";
import {Router} from "@angular/router";

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {

  public profileImageUrl: string;
  public profileName: string;
  public profileEmail: string;

  public user: any;

  constructor(private googleAuthenticationService: GoogleAuthenticationService, private router: Router) {
    this.profileImageUrl = '';
    this.profileName = '';
    this.profileEmail = '';
    this.user = this.googleAuthenticationService.idToken.getValue();
  }

  public ngOnInit() {
    this.getUserIcon();
    this.getUserEmail();
    this.getUserFullname();
  }

  private getUserEmail() {
    let profileEmail: string = this.user.email;
    profileEmail = profileEmail.trim();
    if (profileEmail.length > 0) {
      this.profileEmail = profileEmail;
    }
  }

  private getUserFullname() {
    let profileName: string = this.user.name;
    profileName = profileName.trim();
    if (profileName.length > 0) {
      this.profileName = profileName;
    }
  }

  private getUserIcon(): void {
    this.profileImageUrl = this.user.picture;
  }

  public logout(): void {
    this.googleAuthenticationService.logout(() => {
      this.router.navigate(["/login"]);
    });
  }

}
