import { UsersModule } from './../../users/users.module';
import { RouterModule } from '@angular/router';
import { GoogleSigninModule } from './../google-signin/google-signin.module';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { NavComponent } from './nav.component';
import { AccountMenuModule } from '../account-menu/account-menu.module';
import { GoogleMenuModule } from '../google-menu/google-menu.module';
import { CrmLoaderModule } from 'projects/crm-loader/src/public-api';
import { CrmToasterModule } from 'projects/crm-toaster/src/public-api';
import { GdprDeclarationModule } from '../gdpr-declaration/gdpr-declaration.module';





@NgModule({
  declarations: [
    NavComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatSidenavModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    GoogleSigninModule,
    RouterModule,
    AccountMenuModule,
    CrmLoaderModule,
    UsersModule,
    GoogleMenuModule,
    CrmToasterModule,
    GdprDeclarationModule
  ],
  exports: [
    NavComponent
  ]
})
export class NavModule { }
