import {ReportingComponent} from './reporting/reporting.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {CalendarComponent} from './calendar/calendar.component';
import {ContactsComponent} from './contacts/contacts.component';
import {EntitiesComponent} from './entities/entities.component';
import {AuthGuardService} from 'projects/crm-core/src/lib/services/auth/auth-guard.service';
import {UsersComponent} from './users/users.component';
import {GoogleImportComponent} from './google-import/google-import.component';

const routes: Routes = [
  { path: '',
    redirectTo: 'reporting',
    pathMatch: 'full' },
  { path: 'login',
    redirectTo: '',
    pathMatch: 'full' },
  {
    path: 'reporting',
    canActivate: [AuthGuardService],
    component: ReportingComponent
  },
  {
    path: 'contacts',
    canActivate: [AuthGuardService],
    component: ContactsComponent
  },
  {
    path: 'contacts/import',
    canActivate: [AuthGuardService],
    component: GoogleImportComponent
  },
  {
    path: 'entities',
    canActivate: [AuthGuardService],
    component: EntitiesComponent
  },
  {
    path: 'calendar',
    canActivate: [AuthGuardService],
    component: CalendarComponent
  },
  {
    path: 'users',
    canActivate: [AuthGuardService],
    component: UsersComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
