import { JsonProperty } from 'json2typescript';
import { HasUuid } from './base/has-uuid';

export class CalendarEventFlat extends HasUuid {
  @JsonProperty('title', String, true)
  public title = '';
  @JsonProperty('start', Number)
  public start = 0;
  @JsonProperty('end', Number)
  public end = 0;
  @JsonProperty('allDay', Boolean)
  public allDay = false;
  @JsonProperty('comment', String, true)
  public comment = '';

  @JsonProperty('updated', Number, true)
  public updated = 0;

  @JsonProperty('googleID', String, true)
  public googleID = '';
  @JsonProperty('description', String, true)
  public description = '';

  @JsonProperty('location', String, true)
  public location = '';
}
