<div class="box-container" [class.is-mobile]="mobileQuery.matches">
    <div>
        <button mat-fab (click)="returnContact()" [ngClass]="
              mobileQuery.matches ? 'add-button--mobile' : 'add-button--desktop'
            " color="primary">
      <mat-icon>arrow_back</mat-icon>
    </button>
    </div>
    <div>
        <mat-horizontal-stepper #stepper [linear]="true">
            <!-- STEP 1 : SELECTION-->
            <mat-step [editable]="true" [completed]="selectedPerson.length > 0">
                <ng-template matStepLabel>{{ 'contact.import.select_persons' | translate}}</ng-template>
                <p-pickList [source]="contactsToImport" [target]="selectedPerson" [sourceStyle]="{'height':'20vw'}" [targetStyle]="{'height':'20vw'}" [responsive]="true" dragdrop="true" [showSourceControls]="false" [showTargetControls]="false" sourceHeader="{{'contact.import.google_contacts' | translate}}"
                    targetHeader="{{'contact.import.contact_to_import' | translate}}">
                    <ng-template let-person pTemplate="item">
                        <div class="ui-helper-clearfix">
                            <div style="font-size:14px;float:right;margin:7px 5px 7px 0;">
                                {{person.names[0].givenName}} - {{person.names[0].familyName}} - {{person.emailAddresses[0].value}}
                            </div>
                        </div>
                    </ng-template>
                </p-pickList>
                <button class="button" mat-stroked-button color="primary" matStepperNext [disabled]="selectedPerson.length == 0">{{ 'common.next' | translate }}</button>
            </mat-step>

            <!-- STEP 2 : REVIEW-->
            <mat-step>
                <ng-template matStepLabel>{{ 'contact.import.review' | translate}}</ng-template>
                <div *ngIf="reviewedContacts" >
                    <div *ngIf="reviewedContacts.length > 0">
                        <div *ngIf="!reviewedContacts[0].isConflicted; else conflictBlock">
                            <div fxLayout="row" fxLayoutAlign="center center">

                                <mat-card appearance="outlined" class="mat-card-contact" fxFlex="0 1 400px">
                                    <mat-card-header>
                                        <mat-card-title>{{'contact.import.google_contacts' | translate}}</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <p>
                                            <mat-icon>person</mat-icon>{{reviewedContacts[0].newContact.givenName}} {{reviewedContacts[0].newContact.familyName}}
                                        </p>
                                        <p>
                                            <mat-icon>email</mat-icon>{{reviewedContacts[0].newContact.email}}
                                        </p>
                                        <p *ngIf="reviewedContacts[0].newContact.mobilePhone.length > 0 || reviewedContacts[0].newContact.workPhone.length > 0">
                                                <mat-icon matListIcon>phone</mat-icon>
                                                <span>
                                                    <span class="block" *ngIf="reviewedContacts[0].newContact.mobilePhone.length > 0" ><span>{{reviewedContacts[0].newContact.mobilePhone}}</span><span class="type">{{ 'common.information.mobilePhone' | translate}}</span></span>
                                                    <span class="block" *ngIf="reviewedContacts[0].newContact.workPhone.length > 0" ><span>{{reviewedContacts[0].newContact.workPhone}}</span><span class="type">{{ 'common.information.workPhone' | translate}}</span></span>
                                                </span>
                                        </p>

                                        <p *ngIf="reviewedContacts[0].newContact.entity.name !== ''">
                                            <mat-icon>business</mat-icon>{{reviewedContacts[0].newContact.entity.name}}
                                        </p>
                                    </mat-card-content>
                                    <mat-card-actions>
                                        <button class="button" mat-stroked-button color="primary" (click)="onReviewComplete('discard')">{{'common.discard' | translate}}</button>
                                        <button class="button" mat-stroked-button color="primary" (click)="onReviewComplete('google')">{{'common.accept' | translate}}</button>
                                    </mat-card-actions>
                                </mat-card>
                            </div>
                        </div>
                        <ng-template #conflictBlock>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <mat-card appearance="outlined" class="mat-card-contact" fxFlex="0 1 400px">
                                    <mat-card-header>
                                        <mat-card-title>{{'contact.import.google_contacts' | translate}}</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <p>
                                            <mat-icon>person</mat-icon>{{reviewedContacts[0].newContact.givenName}} {{reviewedContacts[0].newContact.familyName}}
                                        </p>
                                        <p>
                                            <mat-icon>email</mat-icon>{{reviewedContacts[0].newContact.email}}
                                        </p>
                                        <p *ngIf="reviewedContacts[0].newContact.mobilePhone.length > 0 || reviewedContacts[0].newContact.workPhone.length > 0">
                                            <mat-icon matListIcon>phone</mat-icon>
                                            <span>
                                                <span class="block" *ngIf="reviewedContacts[0].newContact.mobilePhone.length > 0" ><span>{{reviewedContacts[0].newContact.mobilePhone}}</span><span class="type">{{ 'common.information.mobilePhone' | translate}}</span></span>
                                                <span class="block" *ngIf="reviewedContacts[0].newContact.workPhone.length > 0" ><span>{{reviewedContacts[0].newContact.workPhone}}</span><span class="type">{{ 'common.information.workPhone' | translate}}</span></span>
                                            </span>
                                        </p>
                                        <p *ngIf="reviewedContacts[0].newContact.entity.name !== ''">
                                            <mat-icon>business</mat-icon>{{reviewedContacts[0].newContact.entity.name}}
                                        </p>
                                    </mat-card-content>
                                    <mat-card-actions>
                                        <button class="button" mat-stroked-button color="primary" (click)="onReviewComplete('google')">{{'common.accept' | translate}}</button>
                                    </mat-card-actions>
                                </mat-card>

                                <mat-card appearance="outlined" class="mat-card-contact" fxFlex="0 1 400px">
                                    <mat-card-header>
                                        <mat-card-title>{{'contact.import.crm_contacts' | translate}}</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>
                                        <p>
                                            <mat-icon>person</mat-icon>{{reviewedContacts[0].conflictedContact.givenName}} {{reviewedContacts[0].newContact.familyName}}
                                        </p>
                                        <p>
                                            <mat-icon>email</mat-icon>{{reviewedContacts[0].conflictedContact.email}}
                                        </p>
                                        <p *ngIf="reviewedContacts[0].conflictedContact.mobilePhone.length > 0">
                                            <mat-icon>phone</mat-icon>{{reviewedContacts[0].conflictedContact.mobilePhone}}
                                        </p>
                                        <p *ngIf="reviewedContacts[0].conflictedContact.workPhone.length > 0">
                                            <mat-icon>phone</mat-icon>{{reviewedContacts[0].conflictedContact.workPhone}}
                                        </p>
                                        <p *ngIf="reviewedContacts[0].conflictedContact.entityName !== ''">
                                            <mat-icon>business</mat-icon>{{reviewedContacts[0].conflictedContact.entityName}}
                                        </p>
                                    </mat-card-content>
                                    <mat-card-actions>
                                        <button class="button" mat-stroked-button color="primary" (click)="onReviewComplete('discard')">{{'common.discard' | translate}}</button>
                                    </mat-card-actions>
                                </mat-card>
                            </div>

                            <!-- <button mat-button (click)="onReviewComplete('discard')">DISCARD</button> -->
                        </ng-template>
                    </div>

                    <button class="button" mat-stroked-button color="primary" (click)="onPostReview()" matStepperNext>{{'common.next' | translate}}</button>
                    <button class="button" mat-stroked-button color="primary" (click)="resetLists(); stepper.reset()">{{'common.back' | translate}}</button>
                </div>
            </mat-step>

            <!-- STEP 3 : VALIDATION-->
            <mat-step>
                <ng-template matStepLabel>{{'contact.import.validation' | translate}}</ng-template>
                {{'contact.import.finished' | translate}}
                <p-table #personList [value]="newContactsList" [paginator]="true" [responsive]="true" [scrollable]="true" [rows]="15" [columns]="cols" sortMode="multiple" [globalFilterFields]="['name', 'phone', 'email', 'entityFlat.name']">
                    <ng-template pTemplate="caption">
                        <div class="contact-table-caption" [class.is-mobile]="mobileQuery.matches">
                            <div>
                                <mat-form-field>
                                    <mat-icon matPrefix>filter_list</mat-icon><input type="text" matInput placeholder="{{ 'common.globalFilter' | translate }}" (input)="
                                    personList.filterGlobal($event.target.value, 'contains')
                          " />
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-template>
                    <!-- Table Headers -->
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                {{ col.header | translate }}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                        <!-- Column filter -->
                        <tr>
                            <th *ngFor="let col of columns">
                                <mat-form-field *ngIf="col.subfield; else noSubFieldFilter">
                                    <mat-icon matPrefix>filter_list</mat-icon>
                                    <input matInput type="text" (input)="
                                    personList.filter($event.target.value, col.field+'.'+col.subfield, 'contains')
                          " />
                                </mat-form-field>

                                <ng-template #noSubFieldFilter>
                                    <mat-form-field>
                                        <mat-icon matPrefix>filter_list</mat-icon>
                                        <input matInput type="text" (input)="
                                        personList.filter($event.target.value, col.field, 'contains')
                                " />
                                    </mat-form-field>
                                </ng-template>

                            </th>
                        </tr>
                    </ng-template>
                    <!-- Table Body -->
                    <ng-template #list pTemplate="body" let-person let-columns="columns">
                        <tr [pSelectableRow]="person">
                            <td *ngFor="let col of columns">
                                <span *ngIf="col.subfield; else noSubField">
                  <span *ngIf="person[col.field] != null"> {{ person[col.field][col.subfield] }} </span>
                                </span>
                                <ng-template #noSubField>
                                    <span *ngIf="person[col.field] != null"> {{ person[col.field] }}</span>
                                </ng-template>

                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div>
                    <button class="button" mat-stroked-button color="primary" (click)="importSelectedPerson()">{{'common.save' | translate}}</button>
                    <button class="button" mat-stroked-button color="primary" (click)="resetLists(); stepper.reset(); resetDataTable(personList)">{{'common.reset' | translate}}</button>
                    <button class="button" mat-stroked-button color="primary" matStepperPrevious (click)="resetDataTable(personList)">{{'common.back' | translate}}</button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>
