import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, NgZone, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {GoogleAuthenticationService, UserService} from '@crm/core';
import {ApiGwClientService} from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import {AccessContext} from 'projects/crm-core/src/lib/models/access-context';
import {EndpointEnum} from 'projects/crm-core/src/lib/models/enums/endpoint.enum';
import {FunctionEnum} from 'projects/crm-core/src/lib/models/enums/function.enum';
import {RouteEnum} from 'projects/crm-core/src/lib/models/enums/route.enum';
import {AwsAuthService} from 'projects/crm-core/src/lib/services/auth/aws-auth.service';
import {EventService} from 'projects/crm-core/src/lib/services/event/event.service';
import {MyJsonConvert} from 'projects/crm-core/src/lib/utils/my-json-convert';
import {Subscription} from 'rxjs';
import {AxiosResponse} from 'axios';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnDestroy {

  public mobileQuery: MediaQueryList;
  public isLogged = false;
  public isLoading = false;
  public menuItems = [];
  public
  protected myJsonConvert = new MyJsonConvert();
  private mobileQueryListener: () => void;
  private serviceAuthDoneEventSubscription: Subscription;

  constructor(
    private awsAuthService: AwsAuthService,
    private readonly userservice: UserService,
    private apiGwClientService: ApiGwClientService,
    private googleAuthenticationService: GoogleAuthenticationService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private eventService: EventService,
    private router: Router,
    private ngZone: NgZone,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    this.menuItems = [
      {url: '/', name: 'nav.menu.reporting', level: 0},
      {url: '/contacts', name: 'nav.menu.contacts', level: 0},
      {url: '/entities', name: 'nav.menu.entities', level: 0},
      {url: '/calendar', name: 'nav.menu.calendar', level: 0},
      {url: '/users', name: 'nav.menu.users', level: 0}
    ];
    this.googleAuthenticationService.isSignedIn.asObservable().subscribe((signedIn) => {
      this.isLogged = signedIn;
      const param: any = {};
      const emailG = "mail.com";
      this.ngZone.runOutsideAngular(() => {
        if (signedIn && emailG.length > 0) {
          param.email = emailG;
          this.apiGwClientService.routeGet(
            EndpointEnum.USER,
            RouteEnum.RIGHT_ALL_LAMBDA,
            FunctionEnum.ACCESS_CONTEXT,
            {},
            this,
            (response: AxiosResponse<AccessContext>) => {
              this.userservice.setAccessContext(this.myJsonConvert.deserializeObject(response.data, this.getObjectAccessContextClazz()));
              let authorized = this.userservice.isAuthorized();
              if (authorized) {
                this.ngZone.run(() => {
                  this.awsAuthService.goToUrlAfterLogin();
                  this.isLoading = false;
                });
              }
            });
        }
      });
    });
  }

  navigateHome(): void {
    this.router.navigate(['/reporting']);
  }

  ngOnDestroy(): void {
    this.serviceAuthDoneEventSubscription.unsubscribe();
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
  }

  protected getObjectAccessContextClazz(): new () => AccessContext {
    return AccessContext;
  };
}
