import { Component, OnInit } from '@angular/core';
import { User } from '@crm/core';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { EndpointEnum } from 'projects/crm-core/src/lib/models/enums/endpoint.enum';
import { RouteEnum } from 'projects/crm-core/src/lib/models/enums/route.enum';
import { FunctionEnum } from 'projects/crm-core/src/lib/models/enums/function.enum';
import { MyJsonConvert } from 'projects/crm-core/src/lib/utils/my-json-convert';
import { EventService } from 'projects/crm-core/src/lib/services/event/event.service';
import { TranslateService } from '@ngx-translate/core';
import { SuccessGetEvent } from 'projects/crm-core/src/lib/services/event/success-get.event';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  public users: User[];
  public columns: any[];

  protected myJsonConvert = new MyJsonConvert();
  public isLoading: boolean;

  constructor(
    protected apiGwClientService: ApiGwClientService,
    private eventService: EventService,
    private translatService: TranslateService
  ) {
    this.isLoading = true;
    this.users = [];
    this.columns = [
      { field: 'familyName', header: 'common.information.familyName' },
      {field: 'givenName', header: 'common.information.givenName'},
      { field: 'email', header: 'common.information.email' },
      { field: 'enabled', header: 'common.information.status' }
    ];
  }

  protected getObjectGridClazz(): new () => User {
    return User;
  };

  ngOnInit() {
    
    this.apiGwClientService.routeGet(
      EndpointEnum.USER,
      RouteEnum.ACCOUNT,
      FunctionEnum.GRID_CONTEXT,
      {},
      this,
      (response) => {
        this.isLoading = false;
        this.users = this.myJsonConvert.deserializeArray((response.data.dataSource), this.getObjectGridClazz());
        this.eventService.publish(new SuccessGetEvent(this.translatService.instant('toaster.body.users.counter_users') + this.users.length +
          this.translatService.instant('toaster.body.users.users')));
      }
    )
  }

}
