import {Component, OnInit} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {CalendarEventDto, CalendarEventService, GoogleAuthenticationService} from '@crm/core';
import enLocale from '@fullcalendar/core/locales/en-gb';
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import {TranslateService} from '@ngx-translate/core';
import {ApiGwClientService} from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import {CalendarEvent} from 'projects/crm-core/src/lib/models/calendar-event';
import {EndpointEnum} from 'projects/crm-core/src/lib/models/enums/endpoint.enum';
import {FunctionEnum} from 'projects/crm-core/src/lib/models/enums/function.enum';
import {RouteEnum} from 'projects/crm-core/src/lib/models/enums/route.enum';
import {EventService} from 'projects/crm-core/src/lib/services/event/event.service';

import {ReportingService} from '../../../crm-core/src/lib/services/reporting.service';
import {MyJsonConvert} from '../../../crm-core/src/lib/utils/my-json-convert';
import {CrmCalendarEventDialogComponent} from './crm-calendar-event-dialog/crm-calendar-event-dialog.component';
import {ENV_CONSTANT} from 'projects/crm-app/src/environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {CalendarOptions} from '@fullcalendar/core';

@Component({
  selector: 'crm-calendar',
  templateUrl: './crm-calendar.component.html',
  styleUrls: ['./crm-calendar.component.scss']
})
export class CrmCalendarComponent implements OnInit {
  public eventSubject: BehaviorSubject<CalendarEvent[]> = new BehaviorSubject([]);
  public events: CalendarEvent[] = [];
  public calendarEventsDto: CalendarEventDto[] = [];
  public user: any;
  public options: CalendarOptions;
  public isLoading: boolean;
  public profileEmail: string;
  public calendarURL: string;
  private myJsonConvert: MyJsonConvert = new MyJsonConvert();


  constructor(
    private bottomSheet: MatBottomSheet,
    private translate: TranslateService,
    private apiGwClientService: ApiGwClientService,
    private calendarEventService: CalendarEventService,
    private reportingService: ReportingService,
    public eventService: EventService,
    private googleAuthenticationService: GoogleAuthenticationService
  ) {
    this.user = this.googleAuthenticationService.idToken.getValue();
    this.isLoading = false;
  }

  ngOnInit() {
    this.options = {
      // Active plugins
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, momentPlugin],
      // Callbacks
      eventClick: this.onEventClick.bind(this),
      datesSet: this.onDateChange.bind(this),
      // Other options
      editable: false,
      height: 'parent',
      fixedWeekCount: false,
      // Header buttons
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek'
      },
      // Locale
      locale: 'fr',
      locales: [enLocale, frLocale]
    };

    this.getUserEmail();
    this.calendarURL = 'https://www.google.com/calendar/r/eventedit?tab=rc&authuser=' +
      this.profileEmail + '&src=' + ENV_CONSTANT.calendarId;

    const selectedEvent = this.reportingService.selectedEvent.value;
    if (selectedEvent) {
      this.openDialog(selectedEvent);
      this.reportingService.selectedEvent.next(null);
    }
  }


  /*
   * Function called when the user clicks on a date/time.
   * @param info : vanilla JS object with the following properties (low level JS properties omitted, see FullCalendar doc if needed):
   * date : date for the clicked day/time
   * dateStr: ISO8601 string representation of the date
   * allDay: boolean whether the click happened in an all day cell
   */

  public onEventClick(info): void {
    this.openDialog(
      this.calendarEventService.fromFullCalendarEvent(info.event)
    );
  }

  public openDialog(event: CalendarEvent): void {
    const bottomSheetRef = this.bottomSheet.open(CrmCalendarEventDialogComponent, {
      data: event
    });
    bottomSheetRef.afterDismissed().subscribe(bottomSheetData => {
      if (bottomSheetData) {
        switch (bottomSheetData.action) {
          case ('save'):
            this.save(this.calendarEventService.formToCalendarDto(bottomSheetData.data));
            break;
        }
      }
    });
  }

  public onDateChange(info) {
    this.refreshEvents(
      info.view.activeStart,
      info.view.activeEnd,
      info.view.currentStart,
      info.view.currentEnd
    );
  }

  protected getObjectGridClazz(): new () => CalendarEventDto {
    return CalendarEventDto;
  }

  private getUserEmail() {
    let profileEmail: string = this.user.email;
    profileEmail = profileEmail.trim();
    if (profileEmail.length > 0) {
      this.profileEmail = profileEmail;
    }
  }

  private save(result: CalendarEventDto): void {
    result.updated = 1;
    this.apiGwClientService.routePut(
      EndpointEnum.CRM,
      RouteEnum.CALENDAR_EVENT,
      FunctionEnum.SAVE,
      result,
      this,
      (response) => {
        const calendarEventDto: CalendarEventDto = this.myJsonConvert.deserializeObject(response.data, this.getObjectGridClazz());
        const calendarEvent: CalendarEvent = this.calendarEventService.fromCalendarDtoToFullCalendarEvent(calendarEventDto);
        const i = this.events.findIndex(e => e.id === calendarEvent.id);
        const newEvents = this.events;
        if (i >= 0) {
          newEvents[i] = calendarEvent;
          this.events = [...newEvents];
        } else {
          this.events = [...this.events, calendarEvent];
        }


      }
    );
  }

  private refreshEvents(periodStart, periodEnd, currentStart, currentEnd) {
    const params: any = {};
    params.start = (periodStart as Date).getTime();
    params.end = (periodEnd as Date).getTime();
    this.apiGwClientService.routeGet(
      EndpointEnum.CRM,
      RouteEnum.CALENDAR_EVENT,
      FunctionEnum.GRID_CONTEXT,
      params,
      this,
      (response) => {
        this.calendarEventsDto = this.myJsonConvert.deserializeArray(response.data.dataSource, this.getObjectGridClazz());
        this.events = [];
        this.calendarEventsDto.forEach((event) => {
          const calEvent = this.calendarEventService.fromCalendarDtoToFullCalendarEvent(event);
          if (!calEvent.isActive(currentStart, currentEnd)) {
            calEvent.color = '#dddddd';
          }
          this.events.push(calEvent);
        });
        this.eventSubject.next(this.events);
        this.isLoading = false;
      }
    );
  }
}
