// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Backend URLs and endpoints
  backendUrl: "http://localhost:3000/", // adresse du json-server pour les mocks
  contactEndPoint: "contacts/",
  entityEndPoint: "entities/",
  contactFormEndPoint: "contactForm/",
  calendarEventEndPoint: "events/",
};

export const ENV_CONSTANT = {
  production: false,
  name: "RE7", //
  googleId: "864128481489-a40na9f8b9hihdq3mod619kcndtsfs3n.apps.googleusercontent.com", //
  identityPool: "eu-west-1:56edeebd-fe0d-44e6-aa77-6cf9487a66ba", //
  region: "eu-west-1", //
  s3UploadBucket: "crmdaei-re7-upload", //
  backendUrl: "https://aizv8a1sqd.execute-api.eu-west-1.amazonaws.com/re7", //
  apiKey: "AIzaSyBQEM3xidUrxYSd0k3zOTChmVk6VRuYyXY", //
  calendarId: "airliquide.com_56ej7hfj8ltkc5fph9d5ohhmfc@group.calendar.google.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
