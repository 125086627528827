<div class="fab-container" (clickOutside)="onClickOutside()">
    <button mat-fab color="primary" class="fab-toggler"
            (click)="onToggleFab()">
      <i class="material-icons" [@fabToggler]="{value: fabTogglerState}">add</i>
    </button>
    <div [@speedDialStagger]="buttons.length">
      <button *ngFor="let btn of buttons"
              mat-mini-fab
              class="fab-secondary"
              color="secondary">
        <i class="material-icons" (click)="onClickActions(btn.actionType)">{{btn.icon}}</i>
      </button>
    </div>
  </div>
