import { Injectable } from '@angular/core';
import { User } from './../models/user';
import { BehaviorSubject } from 'rxjs';
import { AccessContext } from '../models/access-context';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly acceptGdpr$: BehaviorSubject<boolean>;

  private readonly isAuthorized$: BehaviorSubject<boolean>;


  constructor(  ) {
    this.acceptGdpr$ = new BehaviorSubject<boolean>(false);
    this.isAuthorized$ = new BehaviorSubject<boolean>(false);
  }

  // Returns a new User object with all fields equal to the
  public cloneUser(user: User): User {
    if (user === null) {
      return null;
    }
    const clone = new User();
    for (const prop of Object.keys(user)) {
      clone[prop] = user[prop];
    }
    return clone;
  }

  public hasAcceptedGdpr(): boolean {
    return this.acceptGdpr$.getValue();
  }

  public setAccessContext(data: AccessContext): void {
    this.acceptGdpr$.next(data.acceptGdpr);
    this.isAuthorized$.next(data.authorized);
  }

  public isAuthorized(): boolean {
    return this.isAuthorized$.getValue();
  }

}
