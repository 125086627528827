<button (click)="op2.toggle($event)" [ngStyle]="{'background-image': 'url('+profileImageUrl+')'}" class="account-menu"
        label="Basic" mat-icon-button pButton></button>

<p-overlayPanel #op2>
  <div class="info-container">
    <div class="profile-picture-container">
      <img alt="Profile picture" class="rounded-image" referrerpolicy="no-referrer" src={{profileImageUrl}}>
    </div>
    <div class="profile-information-container">
      <p class="profile-name">
        {{profileName}}
      </p>
      <p class="profile-email">
        {{profileEmail}}
      </p>
    </div>
  </div>
  <div class="actions" (click)="logout()">
    <button color="warn" mat-mini-fab>
      <mat-icon>
        logout
      </mat-icon>
    </button>
  </div>
</p-overlayPanel>
