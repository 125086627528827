<div class="box-container" [class.is-mobile]="mobileQuery.matches">
    <p-table #contactsList [value]="contacts" selectionMode="single" [(selection)]="selectedContact" (onRowSelect)="onRowSelect($event)" [paginator]="true" [responsive]="true" [scrollable]="true" [rows]="15" [columns]="cols" sortMode="multiple" [globalFilterFields]="['name', 'phone', 'email', 'entityFlat.name']">
        <ng-template pTemplate="caption">
            <div class="contact-table-caption" [class.is-mobile]="mobileQuery.matches">
                <div>
                    <mat-form-field>
                        <mat-icon matPrefix>filter_list</mat-icon><input type="text" matInput placeholder="{{ 'common.globalFilter' | translate }}" (input)="
                contactsList.filterGlobal($event.target.value, 'contains')
              " />
                    </mat-form-field>
                </div>
            </div>
        </ng-template>
        <!-- Table Headers -->
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{ col.header | translate }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
            <!-- Column filter -->
            <tr>
                <th *ngFor="let col of columns">
                    <mat-form-field *ngIf="col.subfield; else noSubFieldFilter">
                        <mat-icon matPrefix>filter_list</mat-icon>
                        <input matInput type="text" (input)="
                contactsList.filter($event.target.value, col.field+'.'+col.subfield, 'contains')
              "/>
          </mat-form-field>

                    <ng-template #noSubFieldFilter>
                        <mat-form-field>
                            <mat-icon matPrefix>filter_list</mat-icon>
                            <input matInput type="text" (input)="
                      contactsList.filter($event.target.value, col.field, 'contains')
                    " />
                        </mat-form-field>
                    </ng-template>

                </th>
            </tr>
        </ng-template>
        <!-- Table Body -->
        <ng-template pTemplate="body" let-contact let-columns="columns">
            <tr [pSelectableRow]="contact">
                <td *ngFor="let col of columns">
                    <span *ngIf="col.subfield; else noSubField">
            {{ contact[col.field][col.subfield] }}
                    </span>
                    <ng-template #noSubField>
                        {{ contact[col.field] }}
                    </ng-template>

                </td>
            </tr>
        </ng-template>
    </p-table>
    <crm-speed-dial-fab (actionDial)="handleActionDial($event)"></crm-speed-dial-fab>
</div>
