import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import {AwsAuthService} from './aws-auth.service';
import {GoogleAuthenticationService} from "@crm/core";

@Injectable()
export class AuthGuardService  {

  constructor(
    private router: Router,
    private awsAuthService: AwsAuthService,
    private googleAuthenticationService: GoogleAuthenticationService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.googleAuthenticationService.isLoggedIn()) {
      // logged in so return true
      return true;
    }

    this.awsAuthService.setUrlAfterLogin(state.url);
    // not logged in so redirect to login page with the return url and return false
    return false;
  }
}
