import { UserForm } from '@crm/core';
import { UserService } from '@crm/core';
import { Component, Inject, Input } from '@angular/core';
import { User } from '@crm/core';
import { UserFormContext } from 'projects/crm-core/src/lib/models/form/user-form-context';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { emailValidator } from '../validators/email.validator';
import { emailAirliquideValidator } from '../validators/email-airliquide.validator';
import { ValidatorUtils } from '../validators/validator-utils';

@Component({
  selector: 'crm-users-dialog',
  templateUrl: 'crm-users-dialog.component.html',
  styleUrls: ['crm-users-dialog.component.scss']
})
export class CrmUsersDialogComponent {
  @Input()
  public checked: boolean;
  public userFormGroup: UntypedFormGroup;
  public userHasChanged = false;
  public userForm: UserForm;
  public user: User;
  public isEditable = false;

  public statusEnabled: boolean;
  public updatedProfile: UserFormContext;
  constructor(
    public bottomSheetRef: MatBottomSheetRef<CrmUsersDialogComponent>,
    public userservice: UserService,
    private fb: UntypedFormBuilder,
    public validatorUtils: ValidatorUtils,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.userFormGroup = this.fb.group({
      familyName: ['', [Validators.required, Validators.minLength(2)]],
      givenName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [emailValidator, emailAirliquideValidator, Validators.email, Validators.required]]
    });
    // initialize user
    if (this.data === null) {
      this.userForm = new UserForm();
      this.isEditable = true;
    } else {
      this.user = userservice.cloneUser(this.data);
      this.statusEnabled = this.user.enabled;
    }
  }

  onChange(enable: boolean): void {
    this.statusEnabled = enable;
    let result: UserFormContext = null;
    result = new UserFormContext();
    result.user = this.userservice.cloneUser(this.user);
    result.user.enabled = enable;
    this.updatedProfile = result;
    this.userHasChanged = this.user.hasChanged(result.user);
  }

  onChangeSave(): void {
    this.bottomSheetRef.dismiss(this.updatedProfile);
  }

  onNoClick(): void {
    this.bottomSheetRef.dismiss(null);
  }

  onSave(): void {
    const { value, valid } = this.userFormGroup;
    if (valid) {
      let result: UserFormContext = null;
      result = new UserFormContext();
      this.userForm.familyName = value.familyName;
      this.userForm.givenName = value.givenName;
      this.userForm.email = value.email;
      result.user = this.userForm;
      this.bottomSheetRef.dismiss(result);
    }

  }
}
