import { NgModule } from '@angular/core';
import { CrmToasterComponent } from './crm-toaster.component';
import { CommonModule } from '@angular/common';
import {ToastContainerDirective, ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


@NgModule({
  declarations: [CrmToasterComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ToastContainerDirective
  ],
  exports: [CrmToasterComponent]
})
export class CrmToasterModule { }
