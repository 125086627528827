import { Injectable } from '@angular/core';

import { Contact } from '../models/contact';
import { ContactDto } from '../models/dto/contact-dto';
import { EntityService } from './entity.service';
import { Entity } from '../models/entity';


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(
    private entityService: EntityService
  ) {}

  // Returns a new Contact object with all fields equal to the
  public cloneContact(contact: Contact): Contact {
    if (contact === null) {
      return null;
    }
    const clone = new Contact();
    for (const prop of Object.keys(contact)) {
      if (prop === "entity") {
        clone[prop] = this.entityService.cloneEntity(contact[prop]);
      } else {
        clone[prop] = contact[prop];
      }
    }
    return clone;
  }

  public contactToDto(c: Contact): ContactDto {
    const contactDto = new ContactDto();
    contactDto.uuid = c.uuid;
    contactDto.mobilePhone = c.mobilePhone;
    contactDto.workPhone = c.workPhone;
    contactDto.givenName = c.givenName;
    contactDto.familyName = c.familyName;
    contactDto.enabled = c.enabled;
    contactDto.email = c.email;
    contactDto.deleted = c.deleted;
    contactDto.comment = c.comment;
    contactDto.entityName = c.entity.name;
    return contactDto;
  }

  public formToContact(value: any): Contact {
    const contact = new Contact();
    contact.uuid = value.uuid;
    contact.familyName = value.familyName;
    contact.givenName = value.givenName;
    contact.email = value.email;
    contact.mobilePhone = value.mobilePhone;
    contact.workPhone = value.workPhone;
    contact.comment = value.comment;
    contact.enabled = value.enabled;
    contact.entity = (value.entity)? this.entityService.cloneEntity(value.entity): new Entity();
    return contact;
  }
}
