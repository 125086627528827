import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Contact, ContactForm, ContactFormContext, ContactService, Entity, EntityService } from '@crm/core';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { BottomSheetData } from 'projects/crm-core/src/lib/models/bottom-sheet-data';
import { FunctionEnum } from 'projects/crm-core/src/lib/models/enums/function.enum';
import { RouteEnum } from 'projects/crm-core/src/lib/models/enums/route.enum';
import { ReplaySubject } from 'rxjs';

import { EndpointEnum } from './../../../../crm-core/src/lib/models/enums/endpoint.enum';
import { MyJsonConvert } from './../../../../crm-core/src/lib/utils/my-json-convert';

@Component({
  selector: 'crm-contacts-dialog',
  templateUrl: 'crm-contacts-dialog.component.html',
  styleUrls: ['crm-contacts-dialog.component.scss']
})
export class CrmContactsDialogComponent implements OnInit {
  @Input()
  public checked: Boolean;
  public entities: Entity[];
  public contact: Contact;
  public entityFormVisible = false;
  public isEditable = false;

  public contactFormGroup: UntypedFormGroup;
  public hasBeenModified: Boolean = false;


  protected formContext: ContactFormContext;
  protected myJsonConvert = new MyJsonConvert();
  public visibleSidebar2 = false;

  public filteredEntityList: ReplaySubject<Entity[]> = new ReplaySubject<Entity[]>(1);

  constructor(
    // public dialogRef: MatDialogRef<CrmContactsDialogComponent>,
    public contactService: ContactService,
    public entityService: EntityService,
    public apiGwClientService: ApiGwClientService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<CrmContactsDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    // this.myJsonConvert.operationMode = OperationMode.LOGGING;

  }

  public ngOnInit() {
    this.contact = new Contact();
    if (this.data === null) {
      this.initForm();
      this.retrieveContactForm(null, false, () => {
        this.setFormControlList();
      });
      this.isEditable = true;
    } else {
      const param = (this.data === null) ? null : this.data.uuid;
      this.retrieveContactForm(param, true, () => {
        this.initForm();
        this.setFormControlList();
      });

    }
  }


  private initForm() {
    this.contactFormGroup = this.fb.group({
      uuid: [this.contact.uuid],
      familyName: [this.contact.familyName, [Validators.required, Validators.minLength(1)]],
      givenName: [this.contact.givenName, [Validators.required, Validators.minLength(1)]],
      email: [this.contact.email, [Validators.email, Validators.required]],
      mobilePhone: [this.contact.mobilePhone],
      workPhone: [this.contact.workPhone],
      comment: [this.contact.comment],
      enabled: [this.contact.enabled],
      entity: [this.contact.entity]
    });

    this.contactFormGroup.valueChanges.subscribe(value => {
      this.hasBeenModified = this.contact.hasChanged(this.contactService.formToContact(value));
    });
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  private retrieveContactForm(data: any, withContact: boolean, callback: () => void) {
    const params: any = {};
    if (data !== null) {
      params.uuid = data;
    }
    this.apiGwClientService.routeGet(
      EndpointEnum.CRM,
      RouteEnum.CONTACT,
      FunctionEnum.FORM_CONTEXT,
      params,
      this,
      (response) => {
        this.formContext = this.myJsonConvert.deserializeObject((response.data as ContactFormContext), this.getFormContextClazz());
        if (withContact) {
          this.contact = this.contactService.cloneContact(this.formContext.objectForm);
        }
        this.entities = Array.from(this.formContext.entityByUuidMap.values());
        this.entities.sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0);
        this.filteredEntityList.next(this.entities.slice());
        callback();
        this.changeDetectorRef.markForCheck();
      }
    );
  }

  private setFormControlList() {
    this.contactFormGroup.removeControl('entity');
    let initValue: null | Entity;
    initValue = this.contact.entity.uuid ? this.contact.entity : null;
    this.contactFormGroup.addControl('entity', new UntypedFormControl(initValue));
    this.contactFormGroup.updateValueAndValidity();
  }

  public compareEntities(entityA: Entity, entityB: Entity): Boolean {
    return entityA && entityB && entityA.equals(entityB);
  }

  protected getFormContextClazz(): new () => ContactFormContext {
    return ContactFormContext;
  };

  onNoClick(): void {
    this.bottomSheetRef.dismiss();
  }

  onUploadContact(): void {
    let bottomSheetData: BottomSheetData = new BottomSheetData;
    bottomSheetData.data = this.contact;
    bottomSheetData.action = "upload";
    this.bottomSheetRef.dismiss(bottomSheetData);
  }

  onSave(): void {
    const { value, valid } = this.contactFormGroup;
    let bottomSheetData: BottomSheetData = new BottomSheetData();
    let result: ContactForm = null;
    if (valid) {
      if (this.entityFormVisible) {
        result = new ContactForm();
        result = this.contactService.formToContact(value);
        result.entity = this.entityService.formToEntity(value.entity);
        result.entity.creation = true;
      } else {
        if (this.hasBeenModified) {
          result = new ContactForm();
          result = this.contactService.formToContact(value);
          result.entity = (value.entity) ? this.entityService.formToEntity(value.entity) : null;
        }
      }

      bottomSheetData.data = result;
      bottomSheetData.action = "save";
    }
    this.bottomSheetRef.dismiss(bottomSheetData);
  }

  public toggleEntityForm() {
    this.entityFormVisible = !this.entityFormVisible;
    if (!this.entityFormVisible) {
      this.setFormControlList();
    }
  }

  public onAnonymize(): void {
    const params: any = {};
    let bottomSheetData: BottomSheetData = new BottomSheetData();
    params.uuid = this.contact.uuid;
    bottomSheetData.data = params;
    bottomSheetData.action = "anonymize";
    this.bottomSheetRef.dismiss(bottomSheetData);
  }

  public toggleEdit() {
    this.isEditable = !this.isEditable;
  }
  public toggleHistory() {
    this.visibleSidebar2 = !this.visibleSidebar2;
  }
}
