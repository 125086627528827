import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class S3Object {

  @JsonProperty('bucket', String)
  public bucket: string = null;

  @JsonProperty('s3Key', String)
  public s3key: string = null;

}
