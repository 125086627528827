<div class="upload-file">

    <input *ngIf="isShowInputFileDiv()" #uploadInput type="file" name="uploadFile-{{getUuid()}}"
      id="uploadFile-{{getUuid()}}" class="inputfile" (change)="onSelected($event)" accept="{{accept}}" maxlength="250" [attr.multiple]="isMultiple ? '' : null">
  
    <button *ngIf="isShowInputFileDiv()" mat-raised-button type="button" class="choose-file-button reset-button"
      [ngStyle]="{'color': (color) ? color : '', 'border-color': (borderColor) ? borderColor : '', 'background-color': (backgroundColor) ? backgroundColor : ''}"
      (click)="onChooseButtonClick()">
      <label for="uploadFile-{{getUuid()}}">
        <span *ngIf="icon">
          <mat-icon [ngClass]="{'with-text': text !== ''}">{{icon}}</mat-icon>
        </span>
        <span *ngIf="fileS3Array.length === 0 ; else fichierSelected">
          <ng-container *ngIf="text !== undefined && text !== null; else elseTemplate">
            <span>{{text}}</span>
          </ng-container>
          <ng-template #elseTemplate>
            <span translate>UPLOAD_FILE.CHOOSE_FILE</span>
          </ng-template>
        </span>
      </label>
    </button>
  
    <ng-template #fichierSelected>
      <span *ngIf="showSelectedFile" class="nom-fichier">{{getFileS3Name(getFileS3())}}</span>
    </ng-template>
  
    <div class="alert alert-danger" *ngIf="getExtensionErrorSet().size > 0 && showError">
      <div class="alert-items">
        <div class="alert-item static" *ngFor="let erreurExtension of getExtensionErrorSet()">
          <div class="alert-icon-wrapper">
            <i class="mdi mdi-alert-circle mdi-24px" aria-hidden="true"></i>
          </div>
          <div class="alert-text">
            <span translate [translateParams]="{erreurExtension: erreurExtension}"
              translate-param>UPLOAD.EXTENSION.INTERDITE</span>
          </div>
        </div>
      </div>
    </div>
  
  </div>
  
