import { NgModule } from '@angular/core';
import { CrmLoaderComponent } from './crm-loader.component';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [CrmLoaderComponent],
  imports: [
    CommonModule,
    MatProgressBarModule,
    BrowserModule,
    BrowserAnimationsModule,
  ],
  exports: [CrmLoaderComponent]
})
export class CrmLoaderModule { }
