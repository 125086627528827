import { FunctionEnum } from './../../../../crm-core/src/lib/models/enums/function.enum';
import { EndpointEnum } from 'projects/crm-core/src/lib/models/enums/endpoint.enum';
import { Component, OnInit } from '@angular/core';
import { ContactDto, ContactService } from '@crm/core';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { RouteEnum } from 'projects/crm-core/src/lib/models/enums/route.enum';
import { EventService } from 'projects/crm-core/src/lib/services/event/event.service';
import { LoadingAddEvent } from 'projects/crm-core/src/lib/services/event/loading-add.event';
import { SuccessGetEvent } from 'projects/crm-core/src/lib/services/event/success-get.event';
import { MyJsonConvert } from 'projects/crm-core/src/lib/utils/my-json-convert';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  public contacts: ContactDto[];
  public columns: any[];

  protected myJsonConvert = new MyJsonConvert();

  public isLoading: boolean;
  constructor(
    private contactService: ContactService,

    private apiGwClientService: ApiGwClientService,
    public eventService: EventService,
    private readonly translate: TranslateService,

  ) {
    this.isLoading = true;
    this.contacts = [];
    this.columns = [
      { field: 'givenName', header: 'common.information.givenName' },
      { field: 'familyName', header: 'common.information.familyName' },
      { field: 'email', header: 'common.information.email' },
      { field: 'mobilePhone', header: 'common.information.mobilePhone' },
      { field: 'workPhone', header: 'common.information.workPhone' },
      { field: 'entityName', header: 'entity.type' },
      { field: 'enabled', header: 'common.information.status' }
    ];
  }

  protected getObjectGridClazz(): new () => ContactDto {
    return ContactDto;
  }

  ngOnInit() {
    const params: any = {};
    params.offset = 0;
    params.limit = 10;
    this.eventService.publish(new LoadingAddEvent(undefined));
    this.apiGwClientService.routeGet(
      EndpointEnum.CRM,
      RouteEnum.CONTACT,
      FunctionEnum.GRID_CONTEXT,
      params,
      this,
      (response) => {
        this.isLoading = false;
        this.contacts = this.myJsonConvert.deserializeArray((response.data.dataSource), this.getObjectGridClazz());
        this.eventService.publish(new SuccessGetEvent(this.translate.instant('toaster.body.contact.counter_contact') +
          this.contacts.length + this.translate.instant('toaster.body.contact.contacts')));
      }
    );
  }
}
