import { Component, OnInit, Input, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialogRef } from '@angular/material/dialog';
import { Entity } from '../../../models/entity';
import { EntityService } from '../../../services/entity.service';


@Component({
  selector: 'crm-entity-edit',
  templateUrl: './entity-edit.component.html',
  styleUrls: ['./entity-edit.component.scss']
})
export class EntityEditComponent implements OnInit {


  @Input()
  public parentFormGroup: UntypedFormGroup;

  public isEditable = false;
  public entity: Entity;
  public entityFormGroup: UntypedFormGroup;
  public hasBeenModified: Boolean = false;

  constructor(
    public entityService: EntityService,
    private fb: UntypedFormBuilder,
    public bottomSheetRef: MatBottomSheetRef<EntityEditComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any



  ) {
  }

  public ngOnInit() {
    // initialize contact
    if (this.data === null) {
      this.entity = new Entity();
      this.isEditable = true;
    } else {
      this.entity = this.entityService.cloneEntity(this.data);
    }

    this.entityFormGroup = this.fb.group({
      uuid: [this.entity.uuid],
      name: [this.entity.name, [Validators.required, Validators.minLength(1)]],
      address: [this.entity.address],
      phone: [this.entity.phone],
      comment: [this.entity.comment],
      enabled: [this.entity.enabled, [Validators.required]]
    });

    this.entityFormGroup.valueChanges.subscribe(value => {
      this.hasBeenModified = this.entity.hasChanged(value as Entity);
    });

    if (this.parentFormGroup) {
      this.parentFormGroup.removeControl('entity');
      this.parentFormGroup.addControl('entity', this.entityFormGroup);
      this.parentFormGroup.updateValueAndValidity();
    }
  }

  onSave(): void {
    const { value, valid } = this.entityFormGroup;
    if (valid) {
      let result = this.entityService.formToEntity(value);
      this.bottomSheetRef.dismiss(result);
    }
  }

  onNoClick(): void {
    this.bottomSheetRef.dismiss(null);
  }

  public toggleEdit(): void {
    this.isEditable = !this.isEditable;
  }

}
