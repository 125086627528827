import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {Injectable} from "@angular/core";

@Injectable()
export class ValidatorUtils {

  private error: string;

  constructor(
    private readonly translate: TranslateService
  ) { }

  public getErrorMessage(formControl: AbstractControl): string {
    this.error = '';
    this.getMessageEmail(formControl);
    this.getMessageEmailAirliquide(formControl);
    this.getMessageRequired(formControl);

    return this.error;
  }

  private getMessageEmail(formControl: AbstractControl): void {
    if (formControl.getError('email')) {
      this.error = this.translate.instant('error.email');
    }
  }

  private getMessageEmailAirliquide(formControl: AbstractControl): void {
    if (!formControl.getError('email') && formControl.getError('emailAirliquide')) {
      this.error = this.translate.instant('error.email_airliquide');
    }
  }


  private getMessageRequired(formControl: AbstractControl): void {
    if (formControl.getError('required')) {
      this.error = this.translate.instant('error.required');
    }
  }

}
