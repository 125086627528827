import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'crm-loader',
  templateUrl: './crm-loader.component.html',
  styleUrls: ['./crm-loader.component.scss']
})
export class CrmLoaderComponent implements OnInit {

  public value = 0;

  private counter;

  constructor() { }

  public ngOnInit() {
    this.counter = setInterval(() => { this.increase(); }, 5);
  }

  private increase() {
    if (this.value === 100) {
      clearInterval(this.counter);
    } else {
      this.value = this.value + 5;
    }
  }

}
