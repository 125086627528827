import { ApiGwClientService } from './../../../../crm-core/src/lib/api-gw-client/api-gw-client.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  public isLoading: boolean;
  constructor(
    private apiGwClientService: ApiGwClientService
  ) {
    this.isLoading = true;
  }

  ngOnInit() {

    this.isLoading = false;    
  }

}
