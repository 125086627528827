import { Component, OnInit } from '@angular/core';
import {GoogleAuthenticationService} from "@crm/core";

@Component({
  selector: 'app-google-menu',
  templateUrl: './google-menu.component.html',
  styleUrls: [
    './google-menu.component.scss'
  ]
})

export class GoogleMenuComponent implements OnInit {

  public apps = [];
  private profileEmail: string;
  private user: any;

  constructor(
    private googleAuthenticationService: GoogleAuthenticationService
  ) {
    this.user = this.googleAuthenticationService.idToken.getValue();
    this.getUserEmail();
    this.apps = [
      { name: 'Contacts', link: 'https://contacts.google.com/?hl=fr&tab=rC&authuser=' + this.profileEmail, style: { 'background-position': '0 -2622px' } },
      { name: 'Gmail', link: 'https://mail.google.com/mail/?tab=rm&authuser=' + this.profileEmail, style: { 'background-position': '0 -2139px' } },
      { name: 'Agenda', link: 'https://www.google.com/calendar?tab=rc&authuser=' + this.profileEmail, style: { 'background-position': '0 -1794px' } }
    ]
  }

  private getUserEmail() {
    let profileEmail: string = this.user.email;
    profileEmail = profileEmail.trim();
    if (profileEmail.length > 0) {
      this.profileEmail = profileEmail;
    }
  }

  public ngOnInit() {
  }
}
