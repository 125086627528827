<!-- <div *ngIf="isLoading ; else content">
  <crm-loader></crm-loader>
</div>
<ng-template #content> -->
<div class="component-container">
  <div class="button-container">
    <a mat-raised-button target="_blank" href={{calendarURL}}>
      <span class="mdc-button__label">
        <svg width="36" height="36" viewBox="0 0 36 36">
          <path fill="#34A853" d="M16 16v14h4V20z"></path>
          <path fill="#4285F4" d="M30 16H20l-4 4h14z"></path>
          <path fill="#FBBC05" d="M6 16v4h10l4-4z"></path>
          <path fill="#EA4335" d="M20 16V6h-4v14z"></path>
          <path fill="none" d="M0 0h36v36H0z"></path>
        </svg>
        <b>Create</b>
      </span>
    </a>
  </div>
  <div class="calendar-container">
    <full-calendar [options]="options" [events]="eventSubject | async"></full-calendar>
  </div>
</div>
  <!-- </ng-template> -->

