import { JsonObject } from 'json2typescript';
import { JsonProperty } from 'json2typescript';
import { HasUuid } from './has-uuid';

@JsonObject
export class Participant extends HasUuid {

    @JsonProperty('email', String, true)
    public email: string = '';
    
    @JsonProperty('familyName', String, true)
    public familyName: string = '';
    
    @JsonProperty('givenName', String, true)
    public givenName: string = '';

    @JsonProperty('participantType', String, true)
    public participantType: String = '';


    equals(participant: Participant) {
        return (this.email === participant.email &&
        this.familyName === participant.familyName &&
        this.givenName === participant.givenName &&
        this.participantType === participant.participantType)
    }
}