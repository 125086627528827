import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class GOrganization {
    @JsonProperty("type", String, true)
    public type: string = "work";
    @JsonProperty("current", Boolean, true)
    public current: boolean = true;
    @JsonProperty("name", String, true)
    public name:string = "";
}