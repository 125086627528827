import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const noop = (): void => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => CrmRichTextEditorComponent),
  multi: true
};

@Component({
  selector: 'crm-rich-text-editor',
  templateUrl: './crm-rich-text-editor.component.html',
  styleUrls: ['./crm-rich-text-editor.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CrmRichTextEditorComponent implements ControlValueAccessor, OnInit {

  @Input()
  public canEdit: boolean;
  public config: {};
  public Editor = ClassicEditor;
  // The internal data model
  private innerValue: any = '';
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // Placeholders for the callbacks which are later provided

  constructor() {
    // this.canEdit = true;
    const removeButtonsArray = [
      'Source',
      'Save',
      'Templates',
      'NewPage',
      'Preview',
      'Print',
      'Cut',
      'Copy',
      'Paste',
      'PasteText',
      'PasteFromWord',
      'Undo',
      'Redo',
      'Replace',
      'Find',
      'SelectAll',
      'Scayt',
      'Form',
      'Checkbox',
      'Radio',
      'TextField',
      'Textarea',
      'Select',
      'Button',
      'ImageButton',
      'HiddenField',
      'Anchor',
      'Image',
      'Flash',
      'Smiley',
      'PageBreak',
      'Iframe',
      'BidiLtr',
      'BidiRtl',
      'ShowBlocks',
      'About',
      'CreateDiv'
    ];
    this.config = {
      toolbar: null,
      toolbarGroups: [
        {name: 'document', groups: ['mode', 'document', 'doctools']},
        {name: 'clipboard', groups: ['clipboard', 'undo']},
        {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
        {name: 'forms', groups: ['forms']},
        '/',
        {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
        {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
        {name: 'links', groups: ['links']},
        {name: 'insert', groups: ['insert']},
        '/',
        {name: 'styles', groups: ['styles']},
        {name: 'colors', groups: ['colors']},
        {name: 'tools', groups: ['tools']},
        {name: 'others', groups: ['others']},
        {name: 'about', groups: ['about']}
      ],
      removeButtons: removeButtonsArray.join(','),
      extraPlugins: 'colorbutton, font',
      removePlugins: 'elementspath',
    };
  }

  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  public ngOnInit(): void {
  }

  // Set touched on blur
  public onBlur(): void {
    this.onTouchedCallback();
  }

  public onKeyUp(event: KeyboardEvent): void {
    const parsedContent = this.parseContent(this.innerValue);
    if (this.value.length > parsedContent.length) {
      this.value = parsedContent;
    }
  }

  // From ControlValueAccessor interface
  public writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  private parseContent(contentHtml: string): string {
    const options = {
      limit: 999999,
      preserveTags: true,
      wordBreak: true,
      suffix: '',
      moreLink: ''
    };
    const trimmedHtml = this.trimHtml(contentHtml, options);
    return trimmedHtml.html;
  }

  private trimHtml(
    html: string,
    options: { limit: number, preserveTags: boolean, wordBreak: boolean, suffix: string, moreLink: string }
  ): { html: string, more: boolean } {

    // options = options || {};

    const limit = options.limit || 100;
    const preserveTags = (typeof options.preserveTags !== 'undefined') ? options.preserveTags : true;
    const wordBreak = (typeof options.wordBreak !== 'undefined') ? options.wordBreak : false;
    const suffix = options.suffix || '...';
    const moreLink = options.moreLink || '';

    const arr: string[] = html.replace(/</g, '\n<')
      .replace(/>/g, '>\n')
      .replace(/\n\n/g, '\n')
      .replace(/^\n/g, '')
      .replace(/\n$/g, '')
      .split('\n');

    let sum = 0;
    let row: string;
    let cut: number;
    let add: number;
    let tagMatch: RegExpMatchArray;
    let tagName: string;
    const tagStack: string[] = [];
    let more = false;

    for (let i = 0; i < arr.length; i++) {

      row = arr[i];
      // count multiple spaces as one character
      const rowCut: string = row.replace(/[ ]+/g, ' ');

      if (!row.length) {
        continue;
      }

      if (row[0] !== '<') {

        if (sum >= limit) {
          row = '';
        } else if ((sum + rowCut.length) >= limit) {
          cut = limit - sum;
          if (row[cut - 1] === ' ') {
            while (cut) {
              cut -= 1;
              if (row[cut - 1] !== ' ') {
                break;
              }
            }
          } else {
            add = row.substring(cut).split('').indexOf(' ');
            // break on halh of word
            if (!wordBreak) {
              if (add !== -1) {
                cut += add;
              } else {
                cut = row.length;
              }
            }
          }

          row = row.substring(0, cut) + suffix;

          if (moreLink) {
            row += `<a href="${moreLink}" style="display:inline">»</a>`;
          }

          sum = limit;
          more = true;
        } else {
          sum += rowCut.length;
        }
      } else if (!preserveTags) {
        row = '';
      } else if (sum >= limit) {
        tagMatch = row.match(/[a-zA-Z]+/);
        tagName = tagMatch ? tagMatch[0] : '';
        if (tagName) {
          if (row.substring(0, 2) !== '</') {
            tagStack.push(tagName);
            row = '';
          } else {
            while (tagStack[tagStack.length - 1] !== tagName && tagStack.length) {
              tagStack.pop();
            }
            if (tagStack.length) {
              row = '';
            }
            tagStack.pop();
          }
        } else {
          row = '';
        }
      }

      arr[i] = row;
    }

    return {
      html: arr.join('\n').replace(/\n/g, ''),
      more
    };
  }

}
