import { UUID } from 'angular2-uuid';
import { FileS3 } from './../../../crm-core/src/lib/models/file-s3';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'crm-upload',
  templateUrl: './crm-upload.component.html',
  styleUrls: ['./crm-upload.component.scss']
})
export class CrmUploadComponent implements OnInit {

  @Input()
  public backgroundColor: string;

  @Input()
  public borderColor: string;

  @Input()
  public color: string;

  @Input()
  public icon: string;

  @Input()
  public isMultiple: boolean;

  @Input()
  public showSelectedFile: boolean;

  @Input()
  public text: string;

  /**
   * Exemple de format d'extension
   * accept=".png,.gif,.jpeg"
   */
  @Input()
  public accept: string;

  @Input()
  public fileS3Array: FileS3[] = [];

  @Output()
  public forbiddenExtensionChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public removeDoneChange: EventEmitter<FileS3> = new EventEmitter<FileS3>();

  @Output()
  public uploadDoneChange: EventEmitter<FileS3> = new EventEmitter<FileS3>();

  @Output()
  public uploadDoneUrlChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('uploadInput')
  public uploadInput: ElementRef;

  public showError: boolean;

  private readonly cacheFile: Map<string, string> = new Map();

  private readonly extensionErrorSet: Set<any> = new Set();

  private readonly showInputFileDiv: boolean;

  private readonly uuid: UUID;

  constructor() {
    this.accept = '*';
    this.showInputFileDiv = true;
    this.showSelectedFile = true;
    this.uuid = UUID.UUID();
  }

  public ngOnInit() {
    this.accept = '*';
  }

  public getExtensionErrorSet(): Set<any> {
    return this.extensionErrorSet;
  }

  public onChooseButtonClick(): void {
    this.uploadInput.nativeElement.value = '';
  }

  public getFileS3(): FileS3 {
    const fileS3Array = this.getFileS3Array();
    return fileS3Array.length > 0 ? fileS3Array[0] : null;
  }

  public getFileS3Name(files3: FileS3): string {
    return files3.fileName;
  }

  public getUuid() {
    return this.uuid;
  }

  public isShowInputFileDiv(): boolean {
    return this.showInputFileDiv;
  }

  public onSelected(event: Event) {
    this.getExtensionErrorSet().clear();
    const file: File = this.getFiles(event)[0];
    if (file) {
      this.treatFile(file);
    } else {
      this.removeFile();
    }
  }

  private createFileS3(byteArray: string, file: File, extension: string): FileS3 {
    const fileS3: FileS3 = new FileS3();
    fileS3.byteArray = byteArray;
    fileS3.fileName = file.name;
    fileS3.fileExtension = extension;
    fileS3.file = file;
    fileS3.fileMIME = file.type;
    fileS3.s3Key = `tmp/${UUID.UUID()}`;
    return fileS3;
  }

  private getFileS3Array(): FileS3[] {
    return (Array.isArray(this.fileS3Array)) ? this.fileS3Array : [];
  }

  private getFiles(event: Event): FileList {
    const target = event.target as HTMLInputElement;
    return target.files;
  }

  private onRemove(fileS3: FileS3) {
    fileS3.byteArray = null;
    if (this.removeDoneChange) {
      this.removeDoneChange.emit(fileS3);
    }
  }

  private removeFile() {
    const fichier: FileS3 = this.getFileS3();
    if (fichier) {
      this.onRemove(fichier);
    }
    this.uploadDoneChange.emit(fichier);
    this.uploadDoneUrlChange.emit(undefined);
  }

  private treatFile(file: File): void {
    const fileName = file.name;
    const idxDot = fileName.lastIndexOf('.') + 1;
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (this.accept === '*') {
      const self = this;
      const reader: FileReader = new FileReader();
      reader.onload = () => {
        const fichier: FileS3 = this.createFileS3((reader.result as string).split(',')[1], file, extFile);
        if (self.uploadDoneChange) {
          self.uploadDoneChange.emit(fichier);
        }
        if (self.uploadDoneUrlChange) {
          self.uploadDoneUrlChange.emit(reader.result.toString());
        }
        self.cacheFile.set(fichier.fileName.toString(), reader.result as string);

        const index = self.fileS3Array.findIndex(f => f.s3Key === fichier.s3Key)
        if (index >= 0 ) {
          self.fileS3Array.push(fichier);
        } else {
          self.fileS3Array.splice(index, 1);
        }
      };
      reader.readAsDataURL(file);
    } else {
      if (this.forbiddenExtensionChange) {
        this.forbiddenExtensionChange.emit();
      }
      this.extensionErrorSet.add({ value: extFile });
      this.showError = true;
    }
  }
}
