import { RouteEnum } from './../../../../crm-core/src/lib/models/enums/route.enum';
import { EndpointEnum } from './../../../../crm-core/src/lib/models/enums/endpoint.enum';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { Component, OnInit } from '@angular/core';
import { Entity, EntityService } from '@crm/core';
import { FunctionEnum } from 'projects/crm-core/src/lib/models/enums/function.enum';
import { MyJsonConvert } from 'projects/crm-core/src/lib/utils/my-json-convert';
import { EventService } from 'projects/crm-core/src/lib/services/event/event.service';
import { TranslateService } from '@ngx-translate/core';
import { SuccessGetEvent } from 'projects/crm-core/src/lib/services/event/success-get.event';
import { LoadingAddEvent } from 'projects/crm-core/src/lib/services/event/loading-add.event';
import { LoadingRemoveEvent } from 'projects/crm-core/src/lib/services/event/loading-remove.event';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss']
})
export class EntitiesComponent implements OnInit {

  public entities: Entity[];
  public columns: any[];

  protected myJsonConvert = new MyJsonConvert();
  public isLoading: boolean;

  constructor(
    private entityService: EntityService,
    private apiGwClientService: ApiGwClientService,
    private eventService: EventService,
    private translate: TranslateService
  ) {
    this.isLoading = true;
    this.entities = [];
    this.columns = [
      { field: 'name', header: 'common.information.name' },
      { field: 'address', header: 'common.information.address' },
      { field: 'phone', header: 'common.information.phone' },
      { field: 'enabled', header: 'common.information.status' }
    ];
  }

  protected getObjectGridClazz(): new () => Entity {
    return Entity;
  };

  ngOnInit() {
    this.apiGwClientService.routeGet(
      EndpointEnum.CRM,
      RouteEnum.ENTITY,
      FunctionEnum.GRID_CONTEXT,
      {},
      this,
      (response) => {
        this.entities = this.myJsonConvert.deserializeArray((response.data.dataSource), this.getObjectGridClazz());
        this.isLoading = false;
        this.eventService.publish(new SuccessGetEvent(this.translate.instant('toaster.body.entities.counter_entities') +
          this.entities.length + this.translate.instant('toaster.body.entities.entities')));
      }
    )
  }
}
