import { BottomSheetData } from './../../../../crm-core/src/lib/models/bottom-sheet-data';
import { UUID } from 'angular2-uuid';
import { FileS3 } from './../../../../crm-core/src/lib/models/file-s3';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CalendarEventDto, CalendarEventService } from '@crm/core';
import * as moment from 'moment';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { Participant } from 'projects/crm-core/src/lib/models/base/participant';
import { CalendarEvent } from 'projects/crm-core/src/lib/models/calendar-event';
import { RouteEnum } from 'projects/crm-core/src/lib/models/enums/route.enum';
import { MyJsonConvert } from 'projects/crm-core/src/lib/utils/my-json-convert';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Contact } from './../../../../crm-core/src/lib/models/contact';
import { ContactUser } from './../../../../crm-core/src/lib/models/contact-user';
import { EndpointEnum } from './../../../../crm-core/src/lib/models/enums/endpoint.enum';
import { FunctionEnum } from './../../../../crm-core/src/lib/models/enums/function.enum';
import { ArrayParticipantConverter } from './../../../../crm-core/src/lib/utils/array-participant-converter';
import { S3Service } from 'projects/crm-core/src/lib/services/s3/s3.service';

@Component({
  selector: 'crm-calendar-event-dialog',
  templateUrl: './crm-calendar-event-dialog.component.html',
  styleUrls: ['./crm-calendar-event-dialog.component.scss']
})
export class CrmCalendarEventDialogComponent implements OnInit{

  @ViewChild('participantInput') participantInput: ElementRef<HTMLInputElement>;
  startInput: ElementRef;
  public isEditable: boolean;
  public isUserAboutToDropFile = false;
  public filteredParticipants: Observable<Participant[]>;
  public currentPersonFilter: string;
  public eventFormGroup: UntypedFormGroup;
  public searching = false;
  public hasBeenModified: boolean = false;
  public participantList: Array<Contact | ContactUser> = new Array<Contact | ContactUser>();
  public participantListToFilter: Array<Contact | ContactUser> = new Array<Contact | ContactUser>();
  public calendarEventDto: CalendarEventDto;
  public pickerType: string = "date";
  public minDate: any = null;

  public acceptFile: string = "*"

  public participantCtrl = new UntypedFormControl();
  private oldData: CalendarEvent;
  public fileS3Array: any[];

  private myJsonConvert: MyJsonConvert = new MyJsonConvert();
  private arrayParticipantConverter: ArrayParticipantConverter = new ArrayParticipantConverter();
  public myErrorStateMatcher: MyErrorStateMatcher = new MyErrorStateMatcher();

  constructor(
    private bottomSheetRef: MatBottomSheetRef<CrmCalendarEventDialogComponent>,
    private calendarEventService: CalendarEventService,
    private fb: UntypedFormBuilder,
    private apiGwClientService: ApiGwClientService,
    private changeDetectorRef: ChangeDetectorRef,
    private s3Service: S3Service,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: CalendarEvent
  ) {
    this.fileS3Array = [];
  }

  public ngOnInit() {
    if (this.data.id === null) {
      this.isEditable = true;
      this.initForm();
      this.retrieveEventForm(this.data, () => {});
    } else {
      this.isEditable = false;
      this.retrieveEventForm(this.data, () => {
        this.initForm();
      });
    }
  }

  public uploadFileDoneChange(file: FileS3): void {
    const uploadKey: UUID = UUID.UUID();
    const uploadList: FileS3[] = [];
    uploadList.push(file);
    this.s3Service.upload(uploadKey, uploadList, () => {
      this.eventFormGroup.get('attachments').value.push(file);
      this.eventFormGroup.updateValueAndValidity();
      this.eventFormGroup.markAsDirty();
      this.changeDetectorRef.markForCheck();
      this.fileS3Array = [];
    });
  }

  public getAttachments() {
    return this.eventFormGroup.get('attachments').value.filter((item) => !item.deleted);
  }

  public removeAttachment(attachment: FileS3) {
    const index = this.eventFormGroup.get('attachments').value.findIndex(a => a.s3Key === attachment.s3Key);
    if (index > -1) {
      attachment.deleted = true;
      this.eventFormGroup.get('attachments').value[index] = attachment;
      this.eventFormGroup.updateValueAndValidity();
    }
  }

  private retrieveEventForm(data: any, callback: () => void) {
    const params: any = {};
    if (data.id !== null) {
      params.uuid = this.data.id;
    }

    this.apiGwClientService.routeGet(
      EndpointEnum.CRM,
      RouteEnum.CALENDAR_EVENT,
      FunctionEnum.FORM_CONTEXT,
      (data.id === null)? null : params,
      this,
      (response) => {
        this.calendarEventDto = this.myJsonConvert.deserializeObject(response.data.objectForm, this.getObjectFormClazz());
        this.data = this.calendarEventService.fromCalendarDtoToFullCalendarEvent(this.calendarEventDto);
        this.oldData = this.calendarEventService.fromCalendarDtoToFullCalendarEvent(this.calendarEventService.cloneEvent(this.calendarEventDto));
        this.participantList = this.arrayParticipantConverter.deserialize(response.data.participantList);
        this.participantListToFilter = [...this.participantList];
        this.filteredParticipants = this.participantCtrl.valueChanges.pipe(
          startWith(null),
          map((participant: string | null) => (participant && typeof participant === 'string') ? this._filter(participant) : this.participantListToFilter.slice())
        )
        this.data.extendedProps.participants.forEach(participant =>{
          this.participantListToFilter.splice(this.participantListToFilter.findIndex(p => p.uuid === participant.uuid) ,1);
        });

        callback();

        this.changeDetectorRef.markForCheck();
      }
    );

  }

  private initForm() {
    this.eventFormGroup = this.fb.group({
      id: [this.data.id],
      title: [this.data.title, [Validators.required, Validators.minLength(2)]],
      start: [moment(this.data.start.getTime())],
      end: [moment(this.data.end.getTime())],
      allDay: [this.data.allDay],
      location: [this.data.extendedProps.location],
      comment: [this.data.extendedProps.comment],
      description: [this.data.extendedProps.description],
      attachments: [this.data.extendedProps.attachments],
      participants: [this.data.extendedProps.participants],
      googleID: [this.data.extendedProps.googleID]
    });

    if (this.data && this.data.allDay) {
      this.setAllDay();
    } else if (this.data && !this.data.allDay) {
      this.setNotAllDay();
    }

    this.eventFormGroup.valueChanges.subscribe(value => {
      this.hasBeenModified = this.oldData.hasChanged(this.calendarEventService.formToCalendarEvent(value));
    });

    this.eventFormGroup.get('start').valueChanges.subscribe(value => {
      if (value) {
        this.minDate = value.clone();
        if (this.eventFormGroup.value.allDay) {
          this.minDate.add(1, 'day');
        }
      }
    })

    this.eventFormGroup.get('allDay').valueChanges.subscribe(value => {
      if (value) {
        this.setAllDay();
      } else {
        this.setNotAllDay();
      }
    });
  }

  public downloadAttachment(attachment: FileS3) {
    window.open(this.s3Service.getUrlForWindowOpen(attachment.s3Key, attachment.fileName, attachment.fileMIME), 'blank');
  }

  private setAllDay(): void {
    if (this.eventFormGroup.contains('startTime'))
      this.eventFormGroup.removeControl('startTime');
    if (this.eventFormGroup.contains('endTime'))
      this.eventFormGroup.removeControl('endTime');
      this.minDate = this.eventFormGroup.value.start.clone();
      this.minDate.add(1, 'day');

    this.eventFormGroup.clearValidators();
    this.eventFormGroup.setValidators((formGroup: UntypedFormGroup) => this.periodDateValidator(formGroup));
    this.eventFormGroup.updateValueAndValidity();
  }

  private setNotAllDay(): void {
    this.eventFormGroup.addControl('startTime', new UntypedFormControl(null));
    this.eventFormGroup.addControl('endTime', new UntypedFormControl(null));

    this.eventFormGroup.clearValidators();
    this.eventFormGroup.setValidators((formGroup: UntypedFormGroup) => this.periodDateTimeValidator(formGroup));

    this.minDate = this.eventFormGroup.value.start.clone();

    if (this.data) {
      if (this.data.start)
        this.eventFormGroup.get('startTime').setValue(this.timeToHoursMinToMs(moment(this.data.start.getTime())));
      if (this.data.end)
        this.eventFormGroup.get('endTime').setValue(this.timeToHoursMinToMs(moment(this.data.end.getTime())));
    }

    this.eventFormGroup.updateValueAndValidity();
  }

  private timeToHoursMinToMs(date: any): number {
    return date.hour()*60*60*1000 + date.minute()*60*1000;
  }

  private periodDateTimeValidator(formGroup: UntypedFormGroup) {
    if (formGroup.value.start && formGroup.value.end && formGroup.value.startTime && formGroup.value.endTime) {
      if ((formGroup.value.start.unix()*1000 + formGroup.value.startTime) < (formGroup.value.end.unix()*1000 + formGroup.value.endTime)) {
        return null;
      }
    }

    return {
      periodDateValidator: {
        valid: false
      }
    }
  }

  private periodDateValidator(formGroup: UntypedFormGroup) {

    if (formGroup.value.start && formGroup.value.end) {
      if (formGroup.value.start.unix()< formGroup.value.end.unix()) {
        return null;
      }
    }

    return {
        periodDateValidator: {
          valid: false
        }
      };
  }

  public remove(participant: Participant): void {
    const index = this.eventFormGroup.get('participants').value.findIndex(p => p.uuid === participant.uuid);

    if (index >= 0) {
       const newList = this.eventFormGroup.get('participants').value;
       newList.splice(index, 1);
       this.eventFormGroup.get('participants').setValue(newList);
      this.participantListToFilter.push(this.participantList.find((participant1) => participant1.uuid === participant.uuid));
    }
    this.eventFormGroup.get('participants').markAsDirty();

    this.participantCtrl.setValue(null);
  }

  public selected(event: MatAutocompleteSelectedEvent): void{
    if (this.eventFormGroup.get('participants').value.indexOf(event.option.value) === -1) {
      const newList = this.eventFormGroup.get('participants').value;
      newList.push(this.calendarEventService.fromContactOrContactUserToParticipant(event.option.value));
      this.eventFormGroup.get('participants').setValue(newList);
    }

    this.participantListToFilter.splice(this.participantListToFilter.findIndex((participant) => participant.uuid === event.option.value.uuid),1);
    this.eventFormGroup.get('participants').markAsDirty();
    this.participantCtrl.setValue(null);
    this.participantInput.nativeElement.blur();
  }

  private _filter(value: string): Array<Contact | ContactUser> {

    const filterValue = value.toLowerCase();
    return this.participantListToFilter.filter(participant => (participant.familyName.toLowerCase().indexOf(filterValue) === 0 || participant.givenName.toLowerCase().indexOf(filterValue) === 0 || participant.email.toLowerCase().indexOf(filterValue) === 0));
  }

  protected getObjectFormClazz(): new () => CalendarEventDto {
    return CalendarEventDto;
  };

  public isSameDay(): boolean {
    return this.calendarEventService.isSameDay(this.data);
  }

  onNoClick(): void {
    this.bottomSheetRef.dismiss(null);
  }

  public edit() {
    this.isEditable = true;
  }

  public onSave() {
    const { value, valid } = this.eventFormGroup;
    if (valid) {
      const bottomSheetData: BottomSheetData = new BottomSheetData();
      bottomSheetData.action = "save";
      bottomSheetData.data = value;
      this.bottomSheetRef.dismiss(bottomSheetData);
    }
  }

  public dropEventContainsFiles(event) {
    return (
      event.dataTransfer.types && event.dataTransfer.types.includes('Files')
    );
  }

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.parent.invalid && control.parent.hasError('periodDateValidator') && control.touched;
  }
}
