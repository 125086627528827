import { ContactDto } from './dto/contact-dto';
import { JsonObject, JsonProperty } from 'json2typescript';

import { Entity } from './entity';
import { ContactBase } from './base/contact-base';

@JsonObject
export class Contact extends ContactBase {

  @JsonProperty('entity', Entity, true)
  public entity: Entity = new Entity();
  
  @JsonProperty('oldEntities', [Entity], true)
  public oldEntities: Entity[] = new Array();

  public equals(c: Contact) {
    return (
      c != null &&
      this.uuid === c.uuid &&
      this.familyName === c.familyName &&
      this.givenName === c.givenName &&
      this.mobilePhone === c.mobilePhone &&
      this.workPhone === c.workPhone &&
      this.email === c.email &&
      this.comment === c.comment &&
      this.enabled === c.enabled
    );
  }

  public seemsLike(c: ContactDto) {
    return (
      c != null &&
      this.familyName === c.familyName &&
      this.givenName === c.givenName &&
      this.mobilePhone === c.mobilePhone &&
      this.workPhone === c.workPhone &&
      this.email === c.email &&
      this.entity.name === c.entityName
    );
  }

  public hasChanged(c: Contact) {
    return !(
      c != null &&
      this.familyName === c.familyName &&
      this.givenName === c.givenName &&
      this.mobilePhone === c.mobilePhone &&
      this.workPhone === c.workPhone &&
      this.email === c.email &&
      this.comment === c.comment &&
      this.enabled === c.enabled &&
      !this.entity.hasChanged(c.entity)
    );
  }
}
