import { CrmLoaderModule } from './../../../../crm-loader/src/lib/crm-loader.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleImportComponent } from './google-import.component';
import { CrmGoogleImportContactModule } from '@crm/google-import-contact';

@NgModule({
  declarations: [GoogleImportComponent],
  imports: [
    CommonModule,
    CrmGoogleImportContactModule,
    CrmLoaderModule
  ]
})
export class GoogleImportModule { }
