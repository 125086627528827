import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CrmCoreModule } from '@crm/core';
import { HttpLoaderFactory } from '@crm/i18n';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { AuthGuardService } from 'projects/crm-core/src/lib/services/auth/auth-guard.service';
import { EventService } from 'projects/crm-core/src/lib/services/event/event.service';
import { CrmLoaderService } from 'projects/crm-loader/src/public-api';
import { CrmToasterModule } from 'projects/crm-toaster/src/public-api';
import { CrmRichTextEditorModule } from 'projects/crm-rich-text-editor/src/public-api';

import { environment } from '../environments/environment';
import { AwsAuthService } from './../../../crm-core/src/lib/services/auth/aws-auth.service';
import { GoogleApiService } from '../../../crm-core/src/lib/services/auth/google-api.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarModule } from './calendar/calendar.module';
import { ContactsModule } from './contacts/contacts.module';
import { EntitiesModule } from './entities/entities.module';
import { GoogleImportModule } from './google-import/google-import.module';
import { ReportingModule } from './reporting/reporting.module';
import { GoogleMenuModule } from './shared/google-menu/google-menu.module';
import { SharedModule } from './shared/shared.module';
import { UsersModule } from './users/users.module';



@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    ContactsModule,
    EntitiesModule,
    CalendarModule,
    GoogleImportModule,
    ReportingModule,
    UsersModule,
    CrmCoreModule.forRoot(environment),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    GoogleMenuModule,
    CrmToasterModule,
    CrmRichTextEditorModule
  ],
  providers: [
    AwsAuthService,
    ApiGwClientService,
    AuthGuardService,
    EventService,
    GoogleApiService,
    CrmLoaderService,
    DatePipe

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
