import { ContactDto } from './dto/contact-dto';
import { JsonProperty } from 'json2typescript';

import { Contact } from './contact';

export class ReviewedContact {

    @JsonProperty("newContact", true)
    public newContact: Contact = new Contact();
    @JsonProperty("conflictedContact", true)
    public conflictedContact: ContactDto = new ContactDto();
    @JsonProperty("isConflicted", true)
    public isConflicted: Boolean = false;
}