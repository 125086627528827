import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EntityService } from '@crm/core';
import { CrmEntitiesModule } from '@crm/entities';

import { EntitiesComponent } from './entities.component';
import { CrmLoaderModule } from 'projects/crm-loader/src/public-api';

@NgModule({
  declarations: [EntitiesComponent],
  imports: [CommonModule, CrmEntitiesModule, HttpClientModule, CrmLoaderModule],
  providers: [EntityService]
})
export class EntitiesModule { }
