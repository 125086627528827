export enum FunctionEnum {
    GRID_CONTEXT = 'GridContext',
    IS_AUTHORIZED = 'IsAuthorized',
    SAVE = 'Save',
    BATCH_SAVE = 'BatchSave',
    FORM_CONTEXT = 'FormContext',
    ANONYMIZE = 'Anonymize',
    REPORTING = 'Reporting',
    REMOVE = 'Remove',
    EXPORT = 'Export',
    ACCEPT_GDPR = "AcceptGdpr",
    ACCESS_CONTEXT = "AccessContext"
  }
