import { Component, Output, EventEmitter } from '@angular/core';
import { speedDialFabAnimations } from './speed-dial-fab.animations';

@Component({
  selector: 'crm-speed-dial-fab',
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: ['./speed-dial-fab.component.scss'],
  animations: speedDialFabAnimations
})
export class SpeedDialFabComponent {
  @Output()
  actionDial = new EventEmitter<string>();
  
  fabButtons = [
    {
      icon: 'add',
      actionType: 'add'
    },
    {
      icon: 'arrow_downward',
      actionType: 'import'
    }
  ];
  buttons = [];
  fabTogglerState = 'inactive';

  constructor() { }

  onClickActions(action) {
    this.actionDial.emit(action);
  }
  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  onClickOutside() {
    if (this.fabTogglerState === 'active') {
      this.hideItems();
    }
  }

}
