<div class="box-container" [class.is-mobile]="mobileQuery.matches">
    <p-table #entitiesList [value]="entities" selectionMode="single" [(selection)]="selectedEntity" (onRowSelect)="onRowSelect($event)" [paginator]="true" [responsive]="true" [scrollable]="true" [rows]="15" [columns]="cols" sortMode="multiple">
        <ng-template pTemplate="caption">
            <div class="entity-table-caption" [class.is-mobile]="mobileQuery.matches">
                <div>
                    <mat-form-field>
                        <mat-icon matPrefix>filter_list</mat-icon><input type="text" matInput placeholder="{{ 'common.globalFilter' | translate }}" (input)="
                entitiesList.filterGlobal($event.target.value, 'contains')
              " />
                    </mat-form-field>
                </div>
            </div>
        </ng-template>
        <!-- Table Headers -->
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    {{ col.header | translate }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
            <!-- Column filter -->
            <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field">
                    <p-multiSelect appendTo="body" *ngSwitchCase="'enabled'" [options]="status" defaultLabel="{{ 'common.information.status' | translate}}" (onChange)="entitiesList.filter($event.value, col.field, 'in')">
                    </p-multiSelect>
                    <mat-form-field *ngSwitchDefault>
                        <mat-icon matPrefix>filter_list</mat-icon>
                        <input matInput type="text" (input)="entitiesList.filter($event.target.value, col.field, 'contains')" />
                    </mat-form-field>
                </th>
            </tr>
        </ng-template>
        <!-- Table Body -->
        <ng-template pTemplate="body" let-entity let-columns="columns">
            <tr [pSelectableRow]="entity">
                <td *ngFor="let col of columns">
                    {{ entity[col.field] }}</td>
            </tr>
        </ng-template>
    </p-table>
    <button mat-icon-button *ngIf="mobileQuery.matches" (click)="addEntity()" class="mobile-add-button">
    <mat-icon>add_circle</mat-icon>
  </button>
    <button mat-fab (click)="addEntity()" [ngClass]="
      mobileQuery.matches ? 'add-button--mobile' : 'add-button--desktop'
    " color="primary">
    <mat-icon>add</mat-icon>
  </button>
</div>

<!-- <ng-template #dialogContent>
    <div>
        <crm-entity-edit></crm-entity-edit>
    </div>
</ng-template> -->
