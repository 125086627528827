import { CalendarEvent } from 'projects/crm-core/src/lib/models/calendar-event';
import { ReportingGrid } from './../models/grid/reporting-grid';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  public selectedEvent: BehaviorSubject<CalendarEvent>;

  constructor() {
    this.selectedEvent = new BehaviorSubject<CalendarEvent>(null);
  }


  public reportingGridToCalendarEvent(r: ReportingGrid): CalendarEvent {
    const ce = new CalendarEvent();

    ce.id = r.uuid;

    return ce;
  }
}
