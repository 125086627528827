import { CrmReportingModule } from './../../../../crm-reporting/src/lib/crm-reporting.module';
import { ReportingComponent } from './reporting.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ReportingComponent],
    imports: [
      CommonModule,
      CrmReportingModule
    ]
  })
  export class ReportingModule { }