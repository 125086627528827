export class ObjectUtils {
    public static isNotNull(value: any): boolean {
      return value !== undefined && value !== null;
    }
  
    public static isNull(value: any): boolean {
      return !this.isNotNull(value);
    }
  
    public static compareEnum(value: any, value2: any): number {
      if (this.isNull(value) && this.isNull(value2)) {
        return 0;
      } else if (this.isNull(value)) {
        return -1;
      } else if (this.isNull(value2)) {
        return -1;
      }
      return value - value2;
    }
  }
  