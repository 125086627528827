<ng-template #dialogContent>
    <h1 mat-dialog-title translate>gdpr_declaration.title</h1>
    <div mat-dialog-content [innerHTML]="'gdpr_declaration.content'|translate"></div>
    <div mat-dialog-actions>
      <button mat-flat-button type="button" class="reset-button" (click)="onRefuseClick()"><span
          translate>gdpr_declaration.refuse</span></button>
      <button mat-flat-button type="submit" class="apply-button" (click)="onAcceptClick()"><span
          translate>gdpr_declaration.accept</span></button>
    </div>
  </ng-template>
  
