import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CrmUsersModule } from '@crm/users';

import { UsersComponent } from './users.component';
import { CrmLoaderModule } from 'projects/crm-loader/src/public-api';

@NgModule({
  declarations: [UsersComponent],
  imports: [
    CommonModule,
    CrmUsersModule,
    HttpClientModule,
    CrmLoaderModule
  ],
  providers: []
})
export class UsersModule { }
