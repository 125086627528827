import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { CrmRichTextEditorComponent } from './crm-rich-text-editor.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';



@NgModule({
  declarations: [CrmRichTextEditorComponent],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        CKEditorModule,
        ReactiveFormsModule,
    ],
  exports: [CrmRichTextEditorComponent]
})
export class CrmRichTextEditorModule { }
