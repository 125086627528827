import { JsonObject, JsonProperty } from 'json2typescript';

import { GEmailAddress } from './field-object/g-email-adress';
import { GName } from './field-object/g-name';
import { GOrganization } from './field-object/g-organization';
import { GPhoneNumber } from './field-object/g-phone-number';
import { GUserDefined } from './field-object/g-user-defined';

@JsonObject
export class GPerson {
    @JsonProperty("names", [GName], true)
    public names: GName[] = new Array();
    @JsonProperty("emailAddresses", [GEmailAddress], true)
    public emailAddresses: GEmailAddress[] = new Array();
    @JsonProperty("phoneNumbers", [GPhoneNumber], true)
    public phoneNumbers: GPhoneNumber[] = new Array();
    @JsonProperty("organizations", [GOrganization], true)
    public organizations: GOrganization[] = new Array();
    @JsonProperty("userDefined", [GUserDefined], true)
    public userDefined: GUserDefined[] = new Array();
}