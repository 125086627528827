import { JsonProperty } from 'json2typescript';
import { Participant } from './participant';

export abstract class ContactBase extends Participant {

    @JsonProperty('deleted', Boolean)
    public deleted: boolean = false;

    @JsonProperty('comment', String, true)
    public comment: string = '';

    @JsonProperty('mobilePhone', String, true)
    public mobilePhone: string = '';

    @JsonProperty('workPhone', String, true)
    public workPhone: string = '';

    @JsonProperty('enabled', Boolean)
    public enabled = true;
}