import { ContactDto } from './../../models/dto/contact-dto';
import { Injectable } from '@angular/core';
import { Entity } from '@crm/core';

import { GAddress } from '../../models/google/field-object/g-address';
import { GEmailAddress } from '../../models/google/field-object/g-email-adress';
import { GName } from '../../models/google/field-object/g-name';
import { GOrganization } from '../../models/google/field-object/g-organization';
import { GPhoneNumber } from '../../models/google/field-object/g-phone-number';
import { GUserDefined } from '../../models/google/field-object/g-user-defined';
import { GPerson } from '../../models/google/g-person';
import { Contact } from './../../models/contact';
import { ContactForm } from './../../models/contactForm';

@Injectable()
export class ContactsApiService {

  private person: GPerson;

  private name: GName = new GName();
  private address: GAddress = new GAddress();
  private emailAddress: GEmailAddress = new GEmailAddress();
  private mobilePhoneNumber: GPhoneNumber = new GPhoneNumber();
  private workPhoneNumber: GPhoneNumber = new GPhoneNumber();
  private organization: GOrganization = new GOrganization();
  private userDfined: GUserDefined = new GUserDefined();

  constructor(
  ) {
  }

  public personToContact(person: GPerson, entities: Entity[]): Contact {
    const contact: Contact = new Contact();

    contact.givenName = person.names[0].givenName;
    contact.familyName = person.names[0].familyName;
    contact.email = person.emailAddresses[0].value;
    if (person.phoneNumbers.length > 0) {
      person.phoneNumbers.forEach((phoneNumber) => {
        if (phoneNumber.type === "work") {
          contact.workPhone = phoneNumber.value;
        }
        if (phoneNumber.type === "mobile") {
          contact.mobilePhone = phoneNumber.value;
        }
      });
    }
    if (person.organizations.length>0) {
      let index = entities.findIndex(e => e.name.trim().toLowerCase().replace(/\s/g, "") === person.organizations[0].name.trim().toLowerCase().replace(/\s/g, ""));
      if (index >= 0) {
        contact.entity = entities[index];
      }
    }
    return contact;
  }

  public contactToContactForm(contact: Contact): ContactForm {
    const contactForm: ContactForm = new ContactForm();

    contactForm.uuid = contact.uuid;
    contactForm.givenName = contact.givenName;
    contactForm.familyName = contact.familyName;
    contactForm.mobilePhone = contact.mobilePhone;
    contactForm.workPhone = contact.workPhone;
    contactForm.enabled = contact.enabled;
    contactForm.email = contact.email;
    contactForm.entity = (contact.entity.name === "") ? null : contact.entity;

    return contactForm;
  }


  public createPerson(contact: Contact): GPerson {
    //TODO: change to get Contact as input this is mock test
    this.name.familyName = contact.familyName;
    this.name.givenName = contact.givenName;

    // this.emailAddress.type = "work";
    this.emailAddress.value = contact.email;

    this.mobilePhoneNumber.type = "mobile";
    this.mobilePhoneNumber.value = contact.mobilePhone;

    this.workPhoneNumber.type = "work";
    this.workPhoneNumber.value = contact.workPhone;

    this.organization.current = true;
    this.organization.name = contact.entity.name;
    // this.organization.type = "work";

    this.userDfined.value = true;

    this.person = new GPerson();
    this.person.emailAddresses.push(this.emailAddress);
    this.person.names.push(this.name);
    this.person.organizations.push(this.organization);
    this.person.phoneNumbers.push(this.mobilePhoneNumber);
    this.person.phoneNumbers.push(this.workPhoneNumber);
    this.person.userDefined.push(this.userDfined);

    return this.person;
  }
}
