import {Component, OnInit} from '@angular/core';
import {GoogleAuthenticationService} from "@crm/core";

declare var google: any;


@Component({
  selector: 'app-google-signin',
  templateUrl: './google-signin.component.html',
  styleUrls: ['./google-signin.component.scss']
})
export class GoogleSigninComponent implements OnInit {

  constructor(private googleAuthenticationService: GoogleAuthenticationService) {
  }

  public ngOnInit(): void {
    this.googleAuthenticationService.init(document.getElementById('google_btn'));
  }

}
