import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ReportingService } from './../../../crm-core/src/lib/services/reporting.service';
import { Subscription } from 'rxjs';
import { MyJsonConvert } from 'projects/crm-core/src/lib/utils/my-json-convert';
import { FunctionEnum } from './../../../crm-core/src/lib/models/enums/function.enum';
import { RouteEnum } from './../../../crm-core/src/lib/models/enums/route.enum';
import { EndpointEnum } from './../../../crm-core/src/lib/models/enums/endpoint.enum';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, ValidatorFn, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { MediaMatcher } from '@angular/cdk/layout';
import { ReportingGrid } from './../../../crm-core/src/lib/models/grid/reporting-grid';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AxiosResponse } from 'axios';
import { S3Object } from './s3-object';
import { S3Service } from 'projects/crm-core/src/lib/services/s3/s3.service';

@Component({
  selector: 'crm-reporting',
  templateUrl: './crm-reporting.component.html',
  styleUrls: ['./crm-reporting.component.scss']
})
export class CrmReportingComponent implements OnInit {

  @ViewChild('dateTypeSelect', { static: true })
  private myDateTypeSelect: MatSelect;

  public mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  public reportingGridList: ReportingGrid[] = [];
  public reportingFormGroup: UntypedFormGroup;
  protected myJsonConvert = new MyJsonConvert();
  public dateTypeCtrl = new UntypedFormControl();
  public startDateCtrl = new UntypedFormControl(null, Validators.required);
  public myErrorStateMatcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  public datepickerOptions: any[] = [];
  public minDate: any = null;
  public isLoading: boolean;

  public startDateSubcription: Subscription;

  constructor(
    private media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private router: Router,
    private reportingService: ReportingService,
    private readonly translate: TranslateService,
    private apiGwClientService: ApiGwClientService,
    private readonly s3Service: S3Service,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);

    this.datepickerOptions = [
      { content: '--', value: '--' },
      { content: this.translate.instant('common.before'), value: 'Before' },
      { content: this.translate.instant('common.after'), value: 'After' },
      { content: this.translate.instant('common.between'), value: 'Between' }
    ];
    this.isLoading = false;
  }

  ngOnInit() {
    this.initForm();
    this.dateTypeCtrl.valueChanges.subscribe(value => {

      switch (value) {
        case ('After'):
          this.setAfterDateForm();
          break;
        case ('Before'):
          this.setBeforeDateForm();
          break;
        case ('Between'):
          this.setBetweenDateForm();
          break;
        default:
          this.removeDateForm();
          break;
      }
    });

    this.startDateCtrl.valueChanges.subscribe(value => {
      if (this.reportingFormGroup.contains('startDate') && this.reportingFormGroup.contains('endDate')) {
        if (value) {
          this.minDate = value.clone();
          this.minDate.add(1, 'days');
        }
      }
    });
  }


  public onShowDetail(event: ReportingGrid) {
    this.reportingService.selectedEvent.next(this.reportingService.reportingGridToCalendarEvent(event));
    this.router.navigate(['/calendar']);
  }

  private removeDateForm(): void {
    if (this.reportingFormGroup.contains('startDate')) {
      this.reportingFormGroup.removeControl('startDate');
    }
    if (this.reportingFormGroup.contains('endDate')) {
      this.reportingFormGroup.removeControl('endDate');
    }

    this.reportingFormGroup.clearValidators();
    this.minDate = null;
    this.reportingFormGroup.updateValueAndValidity();
  }

  private setBetweenDateForm(): void {
    if (this.reportingFormGroup.contains('startDate')) {
      this.reportingFormGroup.removeControl('startDate');
    }
    if (this.reportingFormGroup.contains('endDate')) {
      this.reportingFormGroup.removeControl('endDate');
    }

    this.reportingFormGroup.addControl('startDate', this.startDateCtrl);
    this.reportingFormGroup.addControl('endDate', new UntypedFormControl(null, Validators.required));
    this.reportingFormGroup.setValidators((formGroup: UntypedFormGroup) => this.periodDateValidator(formGroup));
    this.reportingFormGroup.updateValueAndValidity();
  }

  private periodDateValidator(formGroup: UntypedFormGroup) {

    if (formGroup.value.startDate && formGroup.value.endDate) {
      if (formGroup.value.startDate.unix() < formGroup.value.endDate.unix()) {
        return null;
      }
    }

    return {
      periodDateValidator: {
        valid: false
      }
    };
  }

  private setBeforeDateForm(): void {
    if (this.reportingFormGroup.contains('startDate')) {
      this.reportingFormGroup.removeControl('startDate');
    }
    if (this.reportingFormGroup.contains('endDate')) {
      this.reportingFormGroup.removeControl('endDate');
    }
    this.reportingFormGroup.clearValidators();
    this.minDate = null;

    this.reportingFormGroup.addControl('endDate', new UntypedFormControl(null, Validators.required));
    this.reportingFormGroup.updateValueAndValidity();
  }

  private setAfterDateForm(): void {
    if (this.reportingFormGroup.contains('startDate')) {
      this.reportingFormGroup.removeControl('startDate');
    }
    if (this.reportingFormGroup.contains('endDate')) {
      this.reportingFormGroup.removeControl('endDate');
    }
    this.reportingFormGroup.clearValidators();
    this.minDate = null;

    this.reportingFormGroup.addControl('startDate', new UntypedFormControl(null, Validators.required));
    this.reportingFormGroup.updateValueAndValidity();
  }

  public performSearch(): void {
    this.isLoading = true;
    const { value, valid } = this.reportingFormGroup;
    const params: any = {};
    if (valid) {
      if (value.keywords !== '') {
        params.keywords = value.keywords;
      }
      if (value.startDate) {
        params.start = value.startDate.unix() * 1000;
      }
      if (value.endDate) {
        params.end = value.endDate.unix() * 1000;
      }
      this.performRequest(params);
    }
  }

  private performRequest(params: any): void {
    this.apiGwClientService.routeGet(
      EndpointEnum.CRM,
      RouteEnum.REPORTING,
      FunctionEnum.REPORTING,
      params,
      this,
      (response) => {
        console.log(typeof response);
        this.reportingGridList = response.data.dataSource.slice();
        this.isLoading = false;
      }
    );
  }

  protected getGridClazz(): new () => ReportingGrid {
    return ReportingGrid;
  }

  private initForm(): void {
    this.reportingFormGroup = this.fb.group({
      keywords: ['']
    });
  }

  public export(): void {
    const { value, valid } = this.reportingFormGroup;
    const params: any = {};
    if (valid) {
      if (value.keywords !== '') {
        params.keywords = value.keywords;
      }
      if (value.startDate) {
        params.start = value.startDate.unix() * 1000;
      }
      if (value.endDate) {
        params.end = value.endDate.unix() * 1000;
      }



      this.apiGwClientService.routeGet(
        EndpointEnum.CRM,
        RouteEnum.REPORTING,
        FunctionEnum.EXPORT,
        params,
        this,
        (response: AxiosResponse<S3Object>) => {
          const s3Object: S3Object = this.myJsonConvert.deserializeObject(response.data, S3Object);
          window.open(this.s3Service.getUrlForWindowOpenWithoutMime(s3Object.s3key), '_blank');
        }
      );
    }
  }

  public stripHTML(htmlText: string): string {
    return htmlText.replace(/<[^>]*>/g, '');

  }
}


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.parent.invalid && control.parent.hasError('periodDateValidator') && control.touched;
  }
}
