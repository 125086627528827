import {Injectable} from "@angular/core";
import {ENV_CONSTANT} from "../../../../../crm-app/src/environments/environment";
import {BehaviorSubject} from "rxjs";

declare var google: any;
declare var gapi: any;

@Injectable()
export class GoogleAuthenticationService {
  public credential: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public idToken: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isSignedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public constructor() {
    google.accounts.id.initialize({
      client_id: ENV_CONSTANT.googleId,
      auto_select: true,
      callback: (tokenResponse) => {
        const idToken = JSON.parse(atob(tokenResponse.credential.split('.')[1]))
        this.credential.next(tokenResponse.credential);
        this.idToken.next(idToken);
        gapi.load('client', this.gapiInit)
        const tokenClient = google.accounts.oauth2.initTokenClient({
          client_id: ENV_CONSTANT.googleId,
          scope: 'https://www.googleapis.com/auth/contacts',
          callback: () => {
            this.isSignedIn.next(idToken && idToken.email);
          }
        });
        tokenClient.requestAccessToken({
          prompt: '',
          hint: idToken.email
        });
      },
    });
  }

  public isLoggedIn(): boolean {
    return this.idToken.getValue() && this.idToken.getValue().email;
  }

  public init(parent: HTMLElement | null): void {
    google.accounts.id.renderButton(parent, {theme: "outline", shape: "pill"});
    google.accounts.id.prompt();
  }

  public logout(callback: () => void): void {
    google.accounts.id.revoke(this.idToken.getValue().email, done => {
      this.idToken.next(null);
      this.credential.next(null);
      this.isSignedIn.next(false);
      callback();
    })
  }

  private gapiInit() {
    gapi.client.init({})
      .then(function () {
        gapi.client.load('https://www.googleapis.com/discovery/v1/apis/people/v1/rest');
      });
  }

}
