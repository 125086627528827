import { JsonConvert, OperationMode, ValueCheckingMode } from 'json2typescript';
import { PropertyMatchingRule } from 'json2typescript/src/json2typescript/json-convert-enums';

export class MyJsonConvert extends JsonConvert {

  constructor() {
    super();
    // this.operationMode = OperationMode.LOGGING;
    this.valueCheckingMode = ValueCheckingMode.DISALLOW_NULL;
    this.ignorePrimitiveChecks = false;
    this.propertyMatchingRule = PropertyMatchingRule.CASE_STRICT;
  }
}
