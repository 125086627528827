import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mat-as-time-input',
  templateUrl: './mat-as-time-input.component.html',
  styleUrls: ['./mat-as-time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MatAsTimeInputComponent),
    }
  ]
})
export class MatAsTimeInputComponent implements OnInit, ControlValueAccessor {

  public data: number;
  public dateFormGroup: UntypedFormGroup;

  public displayHours = '';

  public displayMinutes = '';
  private propagateChange = (_: any) => { };

  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.initForm();
    this.formatHours();
    this.formatMinutes();
    this.initSubscribe();
  }

  public initForm() {
    this.dateFormGroup = this.fb.group({
      hours: [null, [Validators.max(23), Validators.min(0)]],
      minutes: [null, [Validators.min(0), Validators.max(59)]]
    });
  }

  private initSubscribe(): void {
    this.dateFormGroup.valueChanges.subscribe(value => {
      this.propagateChange(value.hours * 60 * 60 * 1000 + value.minutes * 60 * 1000);
    });
  }

  private formatMinutes(): void {
    this.dateFormGroup.get('minutes').valueChanges.subscribe(value => {
      if (value) {
        if (value < 0) {
          this.displayMinutes = '00';
          this.dateFormGroup.get('minutes').setValue(0);
        }

        if (value < 10 && value >= 0) {
          this.displayMinutes = '0' + value;
        }

        if (value >= 10 && value <= 59) {
          this.displayMinutes = value;
        }

        if (value > 59) {
          this.displayMinutes = '59';
          this.dateFormGroup.get('minutes').setValue(59);
        }

        this.dateFormGroup.updateValueAndValidity();
      } else {
        this.displayMinutes = '00';
      }
    });
  }

  private formatHours(): void {
    this.dateFormGroup.get('hours').valueChanges.subscribe(value => {
      if (value) {
        if (value < 0) {
          this.displayHours = '00';
          this.dateFormGroup.get('hours').setValue(0);
        }

        if (value < 10 && value >= 0) {
          this.displayHours = '0' + value;
        }

        if (value >= 10 && value <= 23) {
          this.displayHours = value;
        }

        if (value > 23) {
          this.displayHours = '23';
          this.dateFormGroup.get('hours').setValue(23);
        }

        this.dateFormGroup.updateValueAndValidity();
      } else {
        this.displayHours = '00';
      }
    });
  }

  /**
   * Write a new value to the element.
   */
    public writeValue(obj: any): void {
      if (obj) {
        this.data = obj;
        const ominutes = Math.floor((this.data / (1000 * 60)) % 60);
        const ohours = Math.floor((this.data / (1000 * 60 * 60)) % 24);

        this.dateFormGroup.patchValue({
          hours: ohours,
          minutes: ominutes
        });

        this.dateFormGroup.updateValueAndValidity();
      }
    }
    /**
     * Set the function to be called
     * when the control receives a change event.
     */
    public registerOnChange(fn: any): void {
      this.propagateChange = fn;
    }
    /**
     * Set the function to be called
     * when the control receives a touch event.
     */
    public registerOnTouched(fn: any): void {

    }


}
