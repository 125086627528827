import { ENV_CONSTANT } from 'projects/crm-app/src/environments/environment';
import { FileS3 } from './../../models/file-s3';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import * as AWS from 'aws-sdk';
import { Credentials } from 'aws-sdk';
import { ServiceAuthDoneEvent } from '../auth/service-auth-done.event';
import { EventService } from '../event/event.service';
import { ValidationErrorEvent } from '../event/validation-error.event';
import { ObjectUtils } from '../utils/object.utils';

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  private localImgPrefix = 'data:';

  private processMap: Map<UUID, Set<string>> = new Map();

  constructor(private eventService: EventService) { }

  public getUrlForWindowOpen(s3Key: string, fileName: string, MIME: string): string {
    return this.getUrl(s3Key, fileName, MIME, undefined);
  }

  // Attention le cache ne doit pas être utiliser sur des fichiers qui sont demandés dans des fenêtres externes
  public getUrl(s3Key: string, fileName: string, MIME: string, cacheS3Map: Map<string, string>): string {
    if (s3Key) {
      if (this.isLocalFile(s3Key)) {
        return s3Key;
      }

      if (cacheS3Map) {
        let img = cacheS3Map.get(s3Key);
        if (img) {
          return img;
        }
        img = this.getUrlFromS3(s3Key, fileName, MIME);
        cacheS3Map.set(s3Key, img);
        return img;
      }
      return this.getUrlFromS3(s3Key, fileName, MIME);
    }
    return undefined;
  }

  public isLocalFile(str: string): boolean {
    if (str) {
      return str.startsWith(this.localImgPrefix);
    }
    return false;
  }

  public upload(uploadKey: UUID, fichierTab: FileS3[], onFinish: (() => void)): void {
    if (fichierTab.length > 0) {
      const uuid: UUID = UUID.UUID();

      fichierTab.forEach((file: FileS3) => {
        let set: Set<string> = this.processMap.get(uploadKey);
        if (ObjectUtils.isNull(set)) {
          set = new Set();
          this.processMap.set(uploadKey, set);
        }
        set.add(file.s3Key);
      });

      let erreur = false;
      const self = this;
      fichierTab.forEach((fichier: FileS3) => {
        (AWS.config.credentials as Credentials).getPromise().then(() => {
          const s3: AWS.S3 = new AWS.S3({ region: ENV_CONSTANT.region });

          const params = { Bucket: ENV_CONSTANT.s3UploadBucket, Key: fichier.s3Key, Body: fichier.file };

          s3.putObject(params, (err, data) => {
            if (ObjectUtils.isNotNull(err)) {
              erreur = true;
            }
            self.processMap.get(uploadKey).delete(fichier.s3Key);

            if (self.processMap.get(uploadKey).size === 0) {
              self.processMap.delete(uploadKey);
              if (erreur) {
                this.eventService.publish(new ValidationErrorEvent({ data: { S3_UPLOAD: 'FAIL' }, status: 406 }));
              } else {
                onFinish.call(undefined);
              }
            }
            console.log(err, data);
          });
        }).catch(() => {
          this.eventService.publish(new ServiceAuthDoneEvent(false));
        });
      });
    } else {
      onFinish.call(undefined);
    }
  }

  private getUrlFromS3(s3Key: string, fileName: string, MIME: string) {
    const s3: AWS.S3 = new AWS.S3();
    const params = {
      Bucket: ENV_CONSTANT.s3UploadBucket,
      Key: s3Key,
      Expires: 60,
      ResponseContentType: MIME,
      ResponseContentDisposition: 'attachment; filename="' + unescape(encodeURIComponent(fileName)) + '"'
    };
    const url = s3.getSignedUrl('getObject', params); // fichier et image
    return url;
  }

  public getUrlForWindowOpenWithoutMime(s3Key: string): string {
    return this.getUrlWihoutMime(s3Key, undefined);
  }

  public getUrlWihoutMime(s3Key: string, cacheS3Map: Map<string, string>): string {
    if (s3Key) {
      if (this.isLocalFile(s3Key)) {
        return s3Key;
      }

      if (cacheS3Map) {
        let img = cacheS3Map.get(s3Key);
        if (img) {
          return img;
        }
        img = this.getUrlFromS3WithoutMime(s3Key);
        cacheS3Map.set(s3Key, img);
        return img;
      }
      return this.getUrlFromS3WithoutMime(s3Key);
    }
    return undefined;
  }

  private getUrlFromS3WithoutMime(s3Key: string): string {
    const s3: AWS.S3 = new AWS.S3();
    const params = { Bucket: ENV_CONSTANT.s3UploadBucket, Key: s3Key, Expires: 60 };
    const url = s3.getSignedUrl('getObject', params); // fichier et image
    return url;
  }
}
