<div class="box-container" [class.is-mobile]="mobileQuery.matches">
    <div class="reporting-form">
        <mat-card appearance="outlined" [formGroup]="reportingFormGroup" (keydown.enter)="performSearch()">
            <mat-card-content>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="{{ 'common.search' | translate}}" formControlName="keywords"
                            type="text">
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="form-field">
                    <mat-select [formControl]="dateTypeCtrl" placeholder="{{ 'common.date' | translate}}">
                        <mat-option *ngFor="let datepickerOption of datepickerOptions" [value]="datepickerOption.value">
                            {{datepickerOption.content}}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="form-field date-picker">
                    <div *ngIf='dateTypeCtrl.value === "After" || dateTypeCtrl.value === "Between"'>
                        <mat-form-field>
                            <input matInput autocomplete="false" [matDatepicker]="picker"
                                placeholder="{{ 'common.after' | translate}}" formControlName="startDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div *ngIf='dateTypeCtrl.value === "Between"'>
                        <span>{{ 'common.and' | translate}}</span>
                    </div>
                    <div *ngIf='dateTypeCtrl.value === "Before" || dateTypeCtrl.value === "Between"'>
                        <mat-form-field>
                            <input matInput autocomplete="false" [matDatepicker]="picker" [min]="minDate"
                                placeholder="{{ 'common.before' | translate}}" formControlName="endDate"
                                [errorStateMatcher]="myErrorStateMatcher">
                            <mat-error>
                                End date should be <strong>after</strong> start date!
                            </mat-error>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="card-actions">
                    <button class="push-right" mat-mini-fab color="primary" (click)="performSearch()"
                        [disabled]="!reportingFormGroup.valid">
                        <mat-icon class="top-side">search</mat-icon>
                    </button>
                </div>
                <div>
                    <button mat-button (click)="export()" *ngIf="reportingGridList.length > 0" >
                        <span translate>reporting.export</span>
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="reporting-result">
        <p-dataView [value]="reportingGridList" [paginator]="true" [rows]="10" [loading]="isLoading">
            <p-header>
                Report Result
                <span>
                    <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                </span>
            </p-header>
            <ng-template let-report pTemplate="listItem">
                <div class="col-12 event-container">
                    <div class="event-detail">
                        <div class="header-row">
                            <div class="event-title">
                                <b>{{report.title}}</b>
                            </div>
                        </div>
                        <div class="event-information">
                            <div class="location" *ngIf="report.location">
                                <mat-icon class="top-side">room</mat-icon>
                                <span>{{report.location}}</span>
                            </div>
                            <div class="description" *ngIf="report.description">
                                <mat-icon class="top-side">description</mat-icon>
                                <span class="content-span" > {{ stripHTML(report.description) }}</span>
                            </div>
                            <div class="comment" *ngIf="report.comment">
                                <mat-icon class="top-side">comment</mat-icon>
                                <span class="content-span">{{ stripHTML(report.comment) }}</span>
                            </div>
                        </div>
                        <div class=event-participants>
                            <mat-chip-listbox>
                                <mat-chip-option *ngFor="let participant of report.participants" [selectable]="false">
                                    {{participant.givenName + " " + participant.familyName}}
                                </mat-chip-option>
                            </mat-chip-listbox>
                        </div>
                    </div>
                    <div class="event-right">
                        <div class="event-date">
                            <div class="start-date">
                                <div>{{report.start | date: 'mediumDate'}}</div>
                                <div *ngIf="!report.allDay">{{report.start | date: 'H:mm'}}</div>
                            </div>
                            <div *ngIf="!report.allDay" class="date-separator">-</div>
                            <div class="end-date" *ngIf="!report.allDay">
                                <div>{{report.end | date: 'mediumDate'}}</div>
                                <div>{{report.end | date: 'H:mm'}}</div>
                            </div>
                        </div>
                        <div class="event-detail">
                            <button mat-mini-fab (click)="onShowDetail(report)" color="primary">
                                <mat-icon class="top-side">search</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template let-report pTemplate="gridItem">
                <p-card class="col-12 md:col-3 card-result" [header]="report.title">
                    <div class="card-content">
                        <div class="event-date">
                            <div class="start-date">
                                <div>{{report.start | date: 'mediumDate'}}</div>
                                <div *ngIf="!report.allDay">{{report.start | date: 'H:mm'}}</div>
                            </div>
                            <div *ngIf="!report.allDay" class="date-separator">-</div>
                            <div class="end-date" *ngIf="!report.allDay">
                                <div>{{report.end | date: 'mediumDate'}}</div>
                                <div>{{report.end | date: 'H:mm'}}</div>
                            </div>
                        </div>
                        <div class="event-information">
                            <div class="event-location" *ngIf="report.location">
                                <mat-icon class="top-side">room</mat-icon>
                                <span>{{report.location}}</span>
                            </div>
                            <div *ngIf="report.description">
                                <mat-icon class="top-side">description</mat-icon>
                                <span class="description ow">{{report.description}}</span>
                            </div>
                            <div *ngIf="report.comment">
                                <mat-icon class="top-side">comment</mat-icon>
                                <span class="comment ow">{{report.comment}}</span>
                            </div>
                        </div>
                        <div class=event-participants>
                            <mat-chip-listbox>
                                <mat-chip-option *ngFor="let participant of report.participants" [selectable]="false">
                                    {{participant.givenName + " " + participant.familyName}}
                                </mat-chip-option>
                            </mat-chip-listbox>
                        </div>
                    </div>
                    <p-footer class="card-footer">
                        <button mat-mini-fab color="primary" (click)="onShowDetail(report)">
                            <mat-icon class="top-side">search</mat-icon>
                        </button>
                    </p-footer>
                </p-card>
            </ng-template>
        </p-dataView>
    </div>
</div>
