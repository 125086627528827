import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import * as AWS from 'aws-sdk';
import {CognitoIdentity, Credentials} from 'aws-sdk';

import {EventService} from '../event/event.service';
import {ServiceAuthDoneEvent} from './service-auth-done.event';

import {ENV_CONSTANT} from 'projects/crm-app/src/environments/environment';
import {Subscription} from 'rxjs';
import {GoogleAuthenticationService} from "@crm/core";

declare let AWSCognito: any;
declare let apigClientFactory: any;

export interface Callback {
  testCallback(result: any, err: any);
}

@Injectable()
export class AwsAuthService implements OnDestroy {
  private static readonly ACCOUNT_GOOGLE: string = 'accounts.google.com';

  // Google Credentials
  private googleUserUpdatedSubscription: Subscription;

  private urlAfterLogin: string;

  // permet de conserver les get qui sont fait au depart de l'application
  private authWaitingGet = [];

  constructor(
    private router: Router,
    private eventService: EventService,
    private googleAuthenticationService: GoogleAuthenticationService
  ) {
    AWS.config.update({
      region: ENV_CONSTANT.region,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: ''
      })
    });
    AWSCognito.config.region = ENV_CONSTANT.region;
    AWSCognito.config.update({accessKeyId: 'null', secretAccessKey: 'null'});

    this.googleAuthenticationService.isSignedIn.asObservable().subscribe((signedIn) => {
      this.authenticateGoogle();
    })
  }

  public ngOnDestroy() {
    this.googleUserUpdatedSubscription.unsubscribe();
  }

  public clearCredentials(sendEvent: boolean): void {
    if (sendEvent) {
      this.eventService.publish(new ServiceAuthDoneEvent(false));
    }

    if (AWS.config.credentials instanceof AWS.CognitoIdentityCredentials) {
      (AWS.config.credentials as AWS.CognitoIdentityCredentials).clearCachedId();
    }
  }

  public createClientCredentials(): Promise<any> {
    this.updateAwsCredentials();
    const self = this;
    return (AWS.config.credentials as Credentials).getPromise().then(() => {
      return self.createApiGw();
    }).catch(() => {
      this.eventService.publish(new ServiceAuthDoneEvent(false));
    });
  }

  public goToUrlAfterLogin(): string {
    const url = this.urlAfterLogin;
    this.router.navigateByUrl(url);
    this.urlAfterLogin = undefined;
    return url;
  }

  public queueRequest(getRequest) {
    this.authWaitingGet.push(getRequest);
  }

  public setUrlAfterLogin(urlAfterLogin: string): void {
    this.urlAfterLogin = urlAfterLogin;
  }

  public updateAwsCredentials(): void {
    const credentials = AWS.config.credentials as AWS.CognitoIdentityCredentials;
    const params = credentials.params as CognitoIdentity.Types.GetIdInput;
    params.Logins[AwsAuthService.ACCOUNT_GOOGLE] = this.googleAuthenticationService.credential.getValue();
  }

  private authenticateGoogle() {
    this.clearCredentials(false);

    // Add the Google access token to the Cognito credentials login map.
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: ENV_CONSTANT.identityPool,
      Logins: {}
    });
    this.updateAwsCredentials();

    this.eventService.publish(new ServiceAuthDoneEvent(true));

    this.authWaitingGet.forEach((getRequest) => {
      getRequest();
    });

    this.authWaitingGet = [];
  }

  private createApiGw(): any {
    const config = {
      accessKey: AWS.config.credentials.accessKeyId,
      secretKey: AWS.config.credentials.secretAccessKey,
      sessionToken: AWS.config.credentials.sessionToken,
      region: ENV_CONSTANT.region, // The region where the API is deployed
      backendUrl: ENV_CONSTANT.backendUrl
    };
    return apigClientFactory.newClient(config);
  }

}
