import { FileS3 } from './../file-s3';
import { CalendarEventFlat } from '../calendar-event-flat';
import { Participant } from 'projects/crm-core/src/lib/models/base/participant';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class CalendarEventDto extends CalendarEventFlat {
  
  @JsonProperty("attachments", [FileS3], true)
  public attachments: FileS3[] = [];
  @JsonProperty("participants", [Participant], true)
  public participants: Participant[] = [];
}
