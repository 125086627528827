<!-- Header : dialog title and Toggle Edit button -->
<div class="dialog-header" *ngIf="!parentFormGroup">
    <h1>{{ 'entity.details.title' | translate }}</h1>
    <button matTooltip="{{ 'common.edit' | translate }}" matTooltipPosition="below" mat-button (click)="toggleEdit()" *ngIf="!isEditable">
    <mat-icon color="primary" fontIcon="fa-edit" fontSet="fas"></mat-icon>
  </button>
</div>

<!-- Entity edit form ; mutually exclusive with entity detail display -->
<div *ngIf="isEditable || parentFormGroup; else displayDetail" class="entity-detail-form-container">
    <div class="entity-detail-form" [formGroup]="entityFormGroup">
        <!-- Name -->
        <mat-form-field class="entity-detail-input-text">
            <mat-icon matPrefix>business</mat-icon>
            <input matInput placeholder="{{ 'common.information.name' | translate }}" value="{{ entity.name }}" formControlName="name" />
        </mat-form-field>
        <!-- Address -->
        <mat-form-field class="entity-detail-input-text">
            <mat-icon matPrefix>room</mat-icon>
            <input matInput placeholder="{{ 'common.information.address' | translate }}" value="{{ entity.address }}" formControlName="address" />
        </mat-form-field>
        <!-- Telephone -->
        <mat-form-field class="entity-detail-input-text">
            <mat-icon matPrefix>phone</mat-icon>
            <input matInput placeholder="{{ 'common.information.phone' | translate }}" value="{{ entity.phone }}" formControlName="phone" />
        </mat-form-field>
        <!-- Comment-->
        <div class="entity-detail-input-text">
            <mat-icon matPrefix>mode_comment</mat-icon>
            <crm-rich-text-editor formControlName="comment" [(ngModel)]="entity.comment"
                    [canEdit]="isEditable"></crm-rich-text-editor>
        </div>
        <mat-slide-toggle color="primary" formControlName="enabled">
            <span *ngIf="entityFormGroup.get('enabled').value; else statusDisabled" translate>common.enabled</span>
            <ng-template #statusDisabled><span translate>common.disabled</span></ng-template>
        </mat-slide-toggle>

    </div>
</div>

<ng-template #displayDetail>
    <mat-list class="entity-display-detail">
        <mat-list-item>
            <mat-icon matListIcon>business</mat-icon>
            <p mat-line>{{entity.name}}</p>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>room</mat-icon>
            <p mat-line>{{entity.address}}</p>
        </mat-list-item>
        <mat-list-item>
            <mat-icon matListIcon>phone</mat-icon>
            <p mat-line>{{entity.phone}}</p>
        </mat-list-item>
        <mat-list-item>
            <p mat-line>
                <mat-slide-toggle color="primary" disabled="true" [checked]="entity.enabled">
                    <span *ngIf="entity.enabled; else statusDisabled" translate>common.enabled</span>
                    <ng-template #statusDisabled><span translate>common.disabled</span></ng-template>
                </mat-slide-toggle>
            </p>
        </mat-list-item>
        <div>
            <mat-list-item>
                <mat-icon matListIcon>mode_comment</mat-icon>

                <div mat-line [innerHTML]="entity.comment"></div>
            </mat-list-item>
        </div>
    </mat-list>
</ng-template>

<!-- Bottom action buttons : close the dialog (cancel) or save (if in edit mode) -->
<div *ngIf="!parentFormGroup">
    <button mat-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>{{ 'common.cancel' | translate }}
  </button>
    <button *ngIf="isEditable && hasBeenModified" mat-button (click)="onSave()" cdkFocusInitial [disabled]="!entityFormGroup.valid">
    <mat-icon>save</mat-icon>{{ 'common.save' | translate }}
  </button>
</div>
