import { Entity } from './../../../../crm-core/src/lib/models/entity';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { GoogleApiService, Contact, ContactDto } from '@crm/core';
import { Component, OnInit } from '@angular/core';
import { GPerson } from 'projects/crm-core/src/lib/models/google/g-person';
import { MyJsonConvert } from 'projects/crm-core/src/lib/utils/my-json-convert';
import { EndpointEnum } from 'projects/crm-core/src/lib/models/enums/endpoint.enum';
import { RouteEnum } from 'projects/crm-core/src/lib/models/enums/route.enum';
import { FunctionEnum } from 'projects/crm-core/src/lib/models/enums/function.enum';

@Component({
  selector: 'app-google-import',
  templateUrl: './google-import.component.html',
  styleUrls: ['./google-import.component.scss'],
})
export class GoogleImportComponent implements OnInit {
  public contacts: ContactDto[];
  private retrievedGoogleContacts: GPerson[] = [];
  public contactsToImport: GPerson[];
  public entities: Entity[];
  public loading = true;

  protected myJsonConvert = new MyJsonConvert();
  private exportStarted = false;
  constructor(
    private googleApiAuthService: GoogleApiService,
    private apiGwClientService: ApiGwClientService
  ) {
    this.contactsToImport = new Array();
  }

  ngOnInit() {
    this.importGoogleContacts(null, true);

    const params: any = {};
    params.offset = 0;
    params.limit = 10;
    this.apiGwClientService.routeGet(
      EndpointEnum.CRM,
      RouteEnum.CONTACT,
      FunctionEnum.GRID_CONTEXT,
      params,
      this,
      (response) => {
        this.contacts = this.myJsonConvert.deserializeArray(
          response.data.dataSource,
          this.getObjectGridClazz()
        );
      }
    );

    this.apiGwClientService.routeGet(
      EndpointEnum.CRM,
      RouteEnum.ENTITY,
      FunctionEnum.GRID_CONTEXT,
      {},
      this,
      (response) => {
        this.entities = this.myJsonConvert.deserializeArray(
          response.data.dataSource,
          this.getEntityGridClazz()
        );
      }
    );
  }

  protected getEntityGridClazz(): new () => Entity {
    return Entity;
  }

  protected getObjectGridClazz(): new () => ContactDto {
    return ContactDto;
  }

  protected getGoogleObjectGridClazz(): new () => GPerson {
    return GPerson;
  }

  private importGoogleContacts(
    nextPageToken: string,
    firstCall: boolean
  ): void {

    const googleContacts = this.googleApiAuthService.getContacts(nextPageToken);
    googleContacts.then(
      (response) => {
        const retrievedGoogleContacts = this.myJsonConvert.deserializeArray(
          response.result.connections,
          this.getGoogleObjectGridClazz()
        );
        for (const person of retrievedGoogleContacts) {
          if (
            person.userDefined.length &&
            person.userDefined[0].value &&
            person.emailAddresses.length > 0 &&
            person.names.length > 0
          ) {
            this.contactsToImport.push(person);
          }
        }
        if (response.result.nextPageToken == null) {
          this.loading = false;
          return;
        }
        this.importGoogleContacts(response.result.nextPageToken, false);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
