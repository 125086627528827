import { JsonObject, JsonProperty } from 'json2typescript';
import { HasUuid } from './base/has-uuid';

@JsonObject
export class Entity extends HasUuid {

  @JsonProperty('name', String, true)
  public name: string = '';
  
  @JsonProperty('address', String, true)
  public address: string = '';

  @JsonProperty('creation', Boolean, true)
  public creation: boolean = false;

  @JsonProperty('comment', String, true)
  public comment: string = '';

  @JsonProperty('phone', String, true)
  public phone: string = '';

  @JsonProperty('enabled', Boolean)
  public enabled = true;

  public equals(e: Entity) {
    return (
      e != null &&
      this.uuid === e.uuid &&
      this.name === e.name &&
      this.enabled === e.enabled &&
      this.address === e.address &&
      this.phone === e.phone &&
      this.comment === e.comment
    );
  }

  public hasChanged(e: Entity) {
    return !(
      e != null &&
      this.name === e.name &&
      this.enabled === e.enabled &&
      this.address === e.address &&
      this.phone === e.phone &&
      this.comment === e.comment
    );
  }
}
