import {ConflictErrorEvent} from './../services/event/conflict-error.event';
import {Injectable} from '@angular/core';
import {AwsAuthService} from '../services/auth/aws-auth.service';
import {EventService} from '../services/event/event.service';
import {ObjectUtils} from '../services/utils/object.utils';
import {Observable, throwError} from 'rxjs';
import {ValidationErrorEvent} from '../services/event/validation-error.event';
import {PreviousObjectVersionEvent} from '../services/event/previous-object-version-event.event';
import {ServiceErrorEvent} from '../services/event/service-error.event';
import {MyJsonConvert} from '../utils/my-json-convert';
import {UUID} from 'angular2-uuid';
import {LoadingAddEvent} from '../services/event/loading-add.event';
import {LoadingRemoveEvent} from '../services/event/loading-remove.event';
import {GoogleAuthenticationService} from "@crm/core";


@Injectable()
export class ApiGwClientService {

  private static readonly ID_TOKEN_GOOGLE_HEADER: string = 'googletoken';
  private static readonly RUNTIME_HEADER: string = 'runtime';
  private static readonly RUNTIME_VALUE: string = 'ANGULAR2';

  private myJsonConvert: MyJsonConvert = new MyJsonConvert();

  constructor(
    private awsAuthService: AwsAuthService,
    private eventService: EventService,
    private googleAuthenticationService: GoogleAuthenticationService
  ) {
  }


  public routeDelete(endpoint, routeName, functionName, myQueryParams, thisOfFonction, thenFunction) {
    const uuid: UUID = UUID.UUID();
    this.eventService.publish(new LoadingAddEvent(uuid));
    this.awsAuthService.createClientCredentials().then((apigClient) => {
      const params = {
        route_name: routeName,
        function_name: functionName
      };

      const additionalParams = this.initAdditionalParams(myQueryParams);
      const backEndRouteFunction = this.getBackendRouteFunction(endpoint, 'Delete', apigClient);
      backEndRouteFunction(params, {}, additionalParams)
        .then((response) => {
          this.eventService.publish(new LoadingRemoveEvent(uuid));
          thenFunction.call(thisOfFonction, response);
        }).catch((response) => {
        this.eventService.publish(new LoadingRemoveEvent(uuid));
        this.serverError(response);
      });
    });
  }

  public routeGet(endpoint, routeName, functionName, myQueryParams, thisOfFonction, thenFunction): Promise<any> | void {
    return this.runRouteGet(true, endpoint, routeName, functionName, myQueryParams, thisOfFonction, thenFunction);
  }

  public routeGetNoLoading(endpoint, routeName, functionName, myQueryParams, thisOfFonction, thenFunction): Promise<any> | void {
    return this.runRouteGet(false, endpoint, routeName, functionName, myQueryParams, thisOfFonction, thenFunction);
  }

  public routePut(endpoint, routeName, functionName, body, thisOfFonction, thenFunction): Promise<any> {
    return this.routePutImpl(endpoint, routeName, functionName, body, thisOfFonction, thenFunction, undefined);
  }

  public routePutCustom(endpoint, routeName, functionName, body, thisOfFonction, thenFunction, customErrorFunction) {
    this.routePutImpl(endpoint, routeName, functionName, body, thisOfFonction, thenFunction, customErrorFunction);
  }

  private getBackendRouteFunction(endpoint: string, httpVerb: string, apigClient): any {
    const normalizedHttpVerb = httpVerb.charAt(0).toUpperCase() + httpVerb.toLowerCase().slice(1);
    const backendRouteSuffix = 'RouteNameFunctionName' + normalizedHttpVerb;
    const backendRouteFunctionName = endpoint + backendRouteSuffix;
    const backEndRouteFunction = apigClient[backendRouteFunctionName];
    return backEndRouteFunction;
  }

  private initAdditionalParams(myQueryParamsArg: any): any {
    const myHeaders = {};
    myHeaders[ApiGwClientService.RUNTIME_HEADER] = ApiGwClientService.RUNTIME_VALUE;
    myHeaders[ApiGwClientService.ID_TOKEN_GOOGLE_HEADER] = this.googleAuthenticationService.credential.getValue();

    let myQueryParams = {};
    if (ObjectUtils.isNotNull(myQueryParamsArg)) {
      myQueryParams = myQueryParamsArg;
    }

    const additionalParams = {
      headers: myHeaders,
      queryParams: myQueryParams
    };

    return additionalParams;
  }

  private isCustomError(err: any): boolean {
    return err.status && err.status === 499;
  }

  private runRouteGet(loading, endpoint, routeName, functionName, myQueryParams, thisOfFonction, thenFunction) {
    let uuid: UUID;
    if (loading) {
      uuid = UUID.UUID();
      this.eventService.publish(new LoadingAddEvent(uuid));
    }

    return this.awsAuthService.createClientCredentials().then((apigClient) => {
      const params = {
        route_name: routeName,
        function_name: functionName
      };

      const additionalParams = this.initAdditionalParams(myQueryParams);
      const backEndRouteFunction = this.getBackendRouteFunction(endpoint, 'Get', apigClient);

      return backEndRouteFunction(params, {}, additionalParams)
        .then((response) => {
          if (uuid) {
            this.eventService.publish(new LoadingRemoveEvent(uuid));
          }
          return thenFunction.call(thisOfFonction, response);
        }).catch((response) => {
          if (uuid) {
            this.eventService.publish(new LoadingRemoveEvent(uuid));
          }
          this.serverError(response);
        });
    });
  }

  private routePutImpl(endpoint, routeName, functionName, body, thisOfFonction, thenFunction, customErrorFunction) {
    const uuid: UUID = UUID.UUID();
    this.eventService.publish(new LoadingAddEvent(uuid));

    return this.awsAuthService.createClientCredentials().then((apigClient) => {
      const params = {
        route_name: routeName,
        function_name: functionName
      };
      const additionalParams = this.initAdditionalParams(undefined);

      let serializeBody;

      if (body) {
        serializeBody = this.myJsonConvert.serializeObject(body);
      }
      if ((!serializeBody || !Object.keys(serializeBody).length)) {
        body['requestUuid'] = uuid;
        serializeBody = body;
      }

      const backEndRouteFunction = this.getBackendRouteFunction(endpoint, 'Put', apigClient);
      backEndRouteFunction(params, serializeBody, additionalParams)
        .then((response) => {
          this.eventService.publish(new LoadingRemoveEvent(uuid));
          return thenFunction.call(thisOfFonction, response);
        }).catch((response) => {
          this.eventService.publish(new LoadingRemoveEvent(uuid));
          if (this.isCustomError(response) && customErrorFunction) {
            customErrorFunction.call(thisOfFonction, response);
          } else {
            this.serverError(response);
          }
        }
      );
    });
  }

  private serverError(err: any): Observable<any> {
    let createThrow = true;
    if (err.status) {
      if (err.status === 0) {
        this.awsAuthService.clearCredentials(true);
      } else if (err.status === 406) {
        this.eventService.publish(new ValidationErrorEvent(err));
        createThrow = false;
      } else if (err.status === 498) {
        this.eventService.publish(new PreviousObjectVersionEvent(err));
        createThrow = false;
      } else if (err.status === 200) {
        createThrow = false;
      } else if (err.status === 409) {
        this.eventService.publish(new ConflictErrorEvent(err));
      }

      if (createThrow) {
        this.eventService.publish(new ServiceErrorEvent(err));
        return throwError(err);
      }
    } else if (err.stack) {
      console.error(err);
    } else if (err) {
      console.error(err);
    }

    return undefined;
  }

}
