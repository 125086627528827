import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class CrmLoaderService {

  public isLoading = new BehaviorSubject(false);
  constructor() { }
}
