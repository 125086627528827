import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgModule, forwardRef } from '@angular/core';
import { MatAsTimeInputComponent } from './mat-as-time-input.component';
import { NgLeftPadPipeModule } from 'angular-pipes';


@NgModule({
  declarations: [MatAsTimeInputComponent],
  imports: [
    MatInputModule,
    ReactiveFormsModule,
  ],
  exports: [MatAsTimeInputComponent]
})
export class MatAsTimeInputModule { }
