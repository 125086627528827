import { CrmLoaderModule } from 'projects/crm-loader/src/public-api';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpLoaderFactory } from '@crm/i18n';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CardModule } from 'primeng/card';
import { DataViewModule } from 'primeng/dataview';

import { CrmReportingComponent } from './crm-reporting.component';



@NgModule({
  declarations: [CrmReportingComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    DataViewModule,
    CardModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatDividerModule,
    CrmLoaderModule
  ],
  exports: [CrmReportingComponent]
})
export class CrmReportingModule { }
