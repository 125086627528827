<div class="event-dialog-container" (dragenter)="isUserAboutToDropFile = dropEventContainsFiles($event)" (dragleave)="isUserAboutToDropFile = false">
    <div class="event-dialog-buttons">
        <button mat-icon-button *ngIf="!isEditable" (click)="edit()">
      <mat-icon color="primary">edit</mat-icon>
    </button>
        <button mat-icon-button *ngIf="isEditable && hasBeenModified" mat-button (click)="onSave()" cdkFocusInitial [disabled]="!eventFormGroup.valid">
      <mat-icon color="primary">save</mat-icon>
    </button>
        <button mat-icon-button (click)="onNoClick()">
      <mat-icon color="primary">close</mat-icon>
    </button>
    </div>

    <!-- Display the details of the event-->

    <div class="event-details-container" *ngIf="!isEditable">
        <div mat-dialog-title class="event-title">{{ data.title }}</div>
        <!-- Dynamic date/time display - Do not display end date/time if same as start date/time-->
        <div class="event-line-container">
            <div class="event-line-icon">
                <mat-icon>schedule</mat-icon>
            </div>
            <div class="event-line-content">
                {{ data.start | date: 'mediumDate' }}
                <span *ngIf="!data.allDay">, {{ data.start | date: 'shortTime' }} </span><span *ngIf="data.end">
          <span *ngIf="!(data.allDay && isSameDay())">
            -
          </span>
                <span *ngIf="!(data.allDay && isSameDay())"> {{ data.end | date: 'mediumDate' }}<span
              *ngIf="!data.allDay">, </span>
                </span>
                <span *ngIf="!data.allDay">{{ data.end | date: 'shortTime' }}</span></span>
            </div>
        </div>

        <div class="event-line-container">
            <div class="event-line-icon">
                <mat-icon>room</mat-icon>
            </div>
            <div class="event-line-content">
                {{ data.extendedProps.location }}
            </div>
        </div>

        <div class="event-line-container">
            <div class="event-line-icon">
                <mat-icon>people</mat-icon>
            </div>
            <div class="event-line-content">
                <mat-chip-listbox>
                    <mat-chip-option
                    *ngFor="let participant of data.extendedProps.participants"
                    [selectable] = "false">
                    {{participant.familyName + " " + participant.givenName}}
                    </mat-chip-option>
                </mat-chip-listbox>
            </div>
        </div>

        <div class="event-line-container">
            <div class="event-line-icon">
                <mat-icon>assignment</mat-icon>
            </div>
            <div class="event-line-content scrollable" [innerHTML]="data.extendedProps.description">
            </div>
        </div>

        <div class="event-line-container">
            <div class="event-line-icon">
                <mat-icon>attachment</mat-icon>
            </div>
            <div class="event-line-content">
                <mat-chip-listbox>
                    <mat-chip-option
                    *ngFor="let attachment of data.extendedProps.attachments"
                    [selectable] = "false"
                    (click)="downloadAttachment(attachment)">
                    {{attachment.fileName}}
                    </mat-chip-option>
                </mat-chip-listbox>
            </div>
        </div>

        <div class="event-line-container">
                <div class="event-line-icon">
                    <mat-icon>comment</mat-icon>
                </div>
                <div class="event-line-content" [innerHTML]="data.extendedProps.comment">
                </div>
            </div>
    </div>

    <!-- Edition form -->
    <div class="event-form-container" [formGroup]="eventFormGroup" *ngIf="isEditable">
        <div mat-dialog-title class="event-title">{{ data.title }}</div>
        <!-- Dynamic date/time display - Do not display end date/time if same as start date/time-->
        <div class="event-line-container">
            <div class="event-line-icon">
                <mat-icon>schedule</mat-icon>
            </div>
            <div class="event-line-content">
                {{ data.start | date: 'mediumDate' }}
                <span *ngIf="!data.allDay">, {{ data.start | date: 'shortTime' }} </span><span *ngIf="data.end">
          <span *ngIf="!(data.allDay && isSameDay())">
            -
          </span>
                <span *ngIf="!(data.allDay && isSameDay())"> {{ data.end | date: 'mediumDate' }}<span
              *ngIf="!data.allDay">, </span>
                </span>
                <span *ngIf="!data.allDay">{{ data.end | date: 'shortTime' }}</span></span>
            </div>
        </div>

        <div class="event-line-container">
            <div class="event-line-icon">
                <mat-icon>room</mat-icon>
            </div>
            <div class="event-line-content">
                {{ data.extendedProps.location }}
            </div>
        </div>

        <div class="event-line-container">
            <div class="event-line-icon">
                <mat-icon>people</mat-icon>
            </div>
            <div class="event-line-content">
                <mat-chip-listbox>
                    <mat-chip-option
                    *ngFor="let participant of data.extendedProps.participants"
                    [selectable] = "false">
                    {{participant.familyName + " " + participant.givenName}}
                    </mat-chip-option>
                </mat-chip-listbox>
            </div>
        </div>


        <div class="event-line-container">
            <div class="event-line-icon">
                <mat-icon>assignment</mat-icon>
            </div>
            <div class="event-line-content scrollable" [innerHTML]="data.extendedProps.description"></div>
        </div>
        <!-- Attachment-->
        <div class="event-line-container">
            <div>
                <crm-upload
                    [fileS3Array]="fileS3Array"
                    [showSelectedFile]="false"
                    [text]="''"
                    [icon]="'attach_file'"
                    [backgroundColor]="'transparent'"
                    [borderColor]="'transparent'"
                    [color]="'#96A5AF'"
                    [accept]="acceptFile"
                    (uploadDoneChange)="uploadFileDoneChange($event)">
                </crm-upload>
            </div>
            <div>
                <mat-chip-listbox #chipList>
                    <mat-chip-option
                    *ngFor="let attachment of getAttachments()"
                    [selectable]="true"
                    (removed)="removeAttachment(attachment)">
                    {{ attachment.fileName }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-option>
                </mat-chip-listbox>
            </div>
        </div>

        <!-- Comment -->
        <div class="event-line-container">
                <div class="event-line-icon">
                    <mat-icon>comment</mat-icon>
                </div>
                <div class="event-line-content">
                    <crm-rich-text-editor formControlName="comment" [(ngModel)]="data.extendedProps.comment"
                    [canEdit]="isEditable"></crm-rich-text-editor>
                    <!-- <mat-form-field class="event-form-input-comment">
                        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" placeholder="{{ 'common.information.comment' | translate }}" formControlName="comment"></textarea>
                    </mat-form-field> -->
                </div>
            </div>
    </div>
</div>
