import { CrmRichTextEditorModule } from './../../../crm-rich-text-editor/src/lib/crm-rich-text-editor.module';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HttpLoaderFactory } from '@crm/i18n';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';

import { ContactsApiService } from './services/google/contacts-api.service';
import {GoogleAuthenticationService} from './services/auth/google-authentication.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // PrimeNg modules
    TableModule,
    // Material modules
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDividerModule,
    MatListModule,
    MatBottomSheetModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MultiSelectModule,
    CrmRichTextEditorModule
  ],
  exports: [],
  providers: [ContactsApiService, GoogleAuthenticationService],

})
export class CrmCoreModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: CrmCoreModule,
      providers: [
        {
          provide: 'env',
          useValue: environment
        }
      ]
    };
  }
}
