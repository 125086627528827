import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMenuComponent } from './google-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';



@NgModule({
  declarations: [GoogleMenuComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    OverlayPanelModule,
    MatGridListModule,
  ],
  exports: [
    GoogleMenuComponent
  ]
})
export class GoogleMenuModule { }
