import { FileS3 } from './file-s3';
import { JsonObject, JsonProperty } from 'json2typescript';
import { Participant } from 'projects/crm-core/src/lib/models/base/participant';

@JsonObject
export class ExtendedProp {
  @JsonProperty("comment", String, true)
  public comment: string;
  @JsonProperty("description", String, true)
  public description: string = "";
  @JsonProperty("location", String, true)
  public location: string = "";
  @JsonProperty("attachments", [FileS3], true)
  public attachments: FileS3[] = [];
  @JsonProperty("participants", [Participant], true)
  public participants: Participant[] = [];

  @JsonProperty("googleID", String, true)
  public googleID: string = "";

  equals(props: ExtendedProp): boolean {
    return (
      this.comment === props.comment &&
      this.location === props.location &&
      this.attachments === props.attachments &&
      this.description === props.description &&
      this.equalsParticipants(props.participants)
    );
  }

  equalsParticipants(participantList: Participant[]): boolean {
    if (this.participants.length !== participantList.length) {
      return false;
    }
    let result = true
    this.participants.forEach(p => {
      if (participantList.findIndex(p2 => p2.equals(p)) === -1) {
        result = false;
      }
    });
    return result;

  }
}
