import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class CrmI18nModule {}

// Required for AOT
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/crm/i18n/', '.json');
}
