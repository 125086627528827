import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CrmCoreModule } from '@crm/core';
import { HttpLoaderFactory } from '@crm/i18n';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { EntityEditComponent } from 'projects/crm-core/src/lib/shared/entity-edit/entity-edit/entity-edit.component';

import { CrmRichTextEditorModule } from './../../../crm-rich-text-editor/src/lib/crm-rich-text-editor.module';
import { CrmContactsComponent } from './crm-contacts.component';
import { CrmContactsDialogComponent } from './dialog/crm-contacts-dialog.component';
import { ClickOutsideDirective } from './speed-dial-fab/click-outside.directive';
import { SpeedDialFabComponent } from './speed-dial-fab/speed-dial-fab.component';



@NgModule({
    declarations: [EntityEditComponent, CrmContactsComponent, CrmContactsDialogComponent, SpeedDialFabComponent, ClickOutsideDirective],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        // PrimeNg modules
        InputTextModule,
        TableModule,
        ButtonModule,
        SidebarModule,
        // Material modules
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        MatDividerModule,
        MatTableModule,
        MatListModule,
        MatTooltipModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MatBottomSheetModule,
        CrmCoreModule,
        CrmRichTextEditorModule
    ],
    exports: [CrmContactsComponent],
    providers: [EntityEditComponent]
})
export class CrmContactsModule { }
