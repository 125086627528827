<div class="time-input" [formGroup]="dateFormGroup">
    <div class="hours">
        <mat-form-field class="t-input">
            <input matInput placeholder="HH" type="number" min="0" max="23" step="1" [value]="displayHours" formControlName="hours">
        </mat-form-field>
        <span class="separator">:</span>
        <mat-form-field class="t-input">
            <input matInput placeholder="mm" type="number" min="0" max="59" step="1" [value]="displayMinutes" formControlName="minutes">
        </mat-form-field>
    </div>
</div>
