import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
  public serialize(date: Date): string {
    if (date == null) {
      return null;
    }
    return date.toISOString();
  }

  public deserialize(date: string): Date {
    if (date) {
      return new Date(date);
    }
    return null;
  }
}
