import { ContactDto } from './../../../crm-core/src/lib/models/dto/contact-dto';
import { SelectItem } from 'primeng/api';
import { ApiGwClientService } from 'projects/crm-core/src/lib/api-gw-client/api-gw-client.service';
import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, NgZone } from '@angular/core';
import { GPerson } from 'projects/crm-core/src/lib/models/google/g-person';
import { MediaMatcher } from '@angular/cdk/layout';
import { Contact, ReviewedContact, Entity, Column, ContactForm } from '@crm/core';
import { ContactsApiService } from 'projects/crm-core/src/lib/services/google/contacts-api.service';
import { EndpointEnum } from 'projects/crm-core/src/lib/models/enums/endpoint.enum';
import { RouteEnum } from 'projects/crm-core/src/lib/models/enums/route.enum';
import { FunctionEnum } from 'projects/crm-core/src/lib/models/enums/function.enum';
import { ContactFormList } from 'projects/crm-core/src/lib/models/form/contact-form-list';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { EventService } from 'projects/crm-core/src/lib/services/event/event.service';
import { SuccessGetEvent } from 'projects/crm-core/src/lib/services/event/success-get.event';

@Component({
  selector: 'crm-google-import-contact',
  templateUrl: './crm-google-import-contact.component.html',
  styleUrls: ['./crm-google-import-contact.component.scss']
})
export class CrmGoogleImportContactComponent implements OnInit {

  @ViewChild('stepper', { static: true })
  private myStepper: MatStepper;

  @ViewChild('list', { static: true })
  private dataTableComponent: Table;
  public mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  @Input()
  public contacts: ContactDto[];
  @Input()
  public contactsToImport: GPerson[];

  @Input()
  public entities: Entity[];

  @Input()
  public cols: any[];

  public newContacts: ContactFormList;

  public reviewedContacts: ReviewedContact[];

  private reviewedContact: ReviewedContact;

  public selectedPerson: GPerson[];
  public newContactsList = new Array<ContactForm>();
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private contactsApiService: ContactsApiService,
    private apiGwClientService: ApiGwClientService,
    private router: Router,
    private media: MediaMatcher,
    private eventService: EventService,
    private translate: TranslateService,
    private ngZone: NgZone
    // public translate: TranslateService

  ) {
    this.selectedPerson = new Array();
    this.reviewedContacts = new Array();
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    this.cols = [
      { field: 'givenName', header: 'common.information.givenName' },
      { field: 'familyName', header: 'common.information.familyName' },
      { field: 'email', header: 'common.information.email' },
      { field: 'mobilePhone', header: 'common.information.mobilePhone' },
      { field: 'workPhone', header: 'common.information.workPhone' },
      { field: 'entity', subfield: 'name', header: 'entity.type' },
      { field: 'enabled', header: 'common.information.status' }
    ];
  }

  public resetLists() {
    this.selectedPerson.forEach((value) => {
      this.contactsToImport.push(value);
    });
    this.reviewedContacts = [];
    this.selectedPerson = [];
  }

  public selectionDone() {
    for (let person of this.selectedPerson) {
      let index = this.contacts.findIndex(c => c.email == person.emailAddresses[0].value);
      this.reviewedContact = new ReviewedContact();
      if (index >= 0) {
        const contact = this.contactsApiService.personToContact(person, this.entities);
        if (this.contacts.findIndex( c => contact.seemsLike(c)) >= 0) {
          // this.selectedPerson.splice(this.selectedPerson.findIndex(p => p.emailAddresses[0].value === person.emailAddresses[0].value), 1);
          this.selectedPerson.slice(index);
        } else {
          this.reviewedContact.newContact = this.contactsApiService.personToContact(person, this.entities);
          this.reviewedContact.isConflicted = true;
          this.reviewedContact.conflictedContact = this.contacts[index];
          this.reviewedContacts.push(this.reviewedContact);

        }
      } else {
        this.reviewedContact.newContact = this.contactsApiService.personToContact(person, this.entities);
        this.reviewedContact.isConflicted = false;
        this.reviewedContacts.push(this.reviewedContact);
      }
    }
    this.newContacts = new ContactFormList();
  }

  public onReviewComplete(choice: any) {
    switch (choice) {
      case ("google"):
        let tempContact = this.reviewedContacts.shift();
        if (tempContact.isConflicted) {
          tempContact.newContact.uuid = tempContact.conflictedContact.uuid;
        }
        this.newContacts.contactList.push(this.contactsApiService.contactToContactForm(tempContact.newContact));
        break;
      case ("discard"):
        this.reviewedContacts.shift();
        break;
      case ("crm"):
        this.reviewedContacts.shift();
        break;
    }
    if (this.reviewedContacts.length === 0) {
      this.onPostReview();
      this.myStepper.next();
    }
  }

  public onPostReview() {
    this.newContacts.contactList.forEach(contact => {
      this.newContactsList.push(contact);
    });
  }

  public importSelectedPerson() {
    this.apiGwClientService.routePut(
      EndpointEnum.CRM,
      RouteEnum.CONTACT,
      FunctionEnum.BATCH_SAVE,
      this.newContacts,
      this,
      (response) => {
        this.eventService.publish(new SuccessGetEvent(this.newContacts.contactList.length + this.translate.instant('contact.import.contact_modified')));
        this.router.navigate(['/contacts']);
      }
    )
  }

  public resetDataTable(personList: any) {
    this.newContactsList = new Array<ContactForm>();
    personList.reset();
  }

  public returnContact() {
    this.router.navigate(['contacts']);
  }
  ngOnInit() {
    this.myStepper.selectionChange.subscribe((stepper) => {
      switch(stepper.selectedIndex){
        case(0):
        {
          break;
        }
        case(1):
        {
          this.selectionDone();
          if (this.reviewedContacts.length === 0) {
            setTimeout(() => {
              this.myStepper.next();
            });
          }
          break;
        }
        case(2):
        {
          if (this.newContacts.contactList.length === 0) {
            this.router.navigate(['/contacts']);
            this.eventService.publish(new SuccessGetEvent(this.translate.instant('contact.import.nothing_to_modify')));
          }
          this.reviewedContacts = [];
          break;
        }
      }
    })
  }

}
